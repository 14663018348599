import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _698c8d60 = () => interopDefault(import('../pages/activate-gift.vue' /* webpackChunkName: "pages/activate-gift" */))
const _7e60ab32 = () => interopDefault(import('../pages/add-bank-account.vue' /* webpackChunkName: "pages/add-bank-account" */))
const _a81b68b2 = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _cedc1bb6 = () => interopDefault(import('../pages/apple-auth.vue' /* webpackChunkName: "pages/apple-auth" */))
const _0030ccb0 = () => interopDefault(import('../pages/appscreens.vue' /* webpackChunkName: "pages/appscreens" */))
const _3a81515d = () => interopDefault(import('../pages/bank-accounts.vue' /* webpackChunkName: "pages/bank-accounts" */))
const _26e5e8f4 = () => interopDefault(import('../pages/barcode.vue' /* webpackChunkName: "pages/barcode" */))
const _95eeb358 = () => interopDefault(import('../pages/barcodeInfo.vue' /* webpackChunkName: "pages/barcodeInfo" */))
const _199a78ae = () => interopDefault(import('../pages/book-a-chat.vue' /* webpackChunkName: "pages/book-a-chat" */))
const _74b69784 = () => interopDefault(import('../pages/christmas-gifts.vue' /* webpackChunkName: "pages/christmas-gifts" */))
const _127808b8 = () => interopDefault(import('../pages/christmaswineinvestment.vue' /* webpackChunkName: "pages/christmaswineinvestment" */))
const _4e64555e = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _78c65e42 = () => interopDefault(import('../pages/collections-cart.vue' /* webpackChunkName: "pages/collections-cart" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _73e24fc1 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _849eb7b6 = () => interopDefault(import('../pages/dryjanuarywineinvestment.vue' /* webpackChunkName: "pages/dryjanuarywineinvestment" */))
const _4d487ba2 = () => interopDefault(import('../pages/en-primeur.vue' /* webpackChunkName: "pages/en-primeur" */))
const _6777a957 = () => interopDefault(import('../pages/enter-amount.vue' /* webpackChunkName: "pages/enter-amount" */))
const _d97eb752 = () => interopDefault(import('../pages/failed-bid.vue' /* webpackChunkName: "pages/failed-bid" */))
const _ae3a8d40 = () => interopDefault(import('../pages/failed-buy.vue' /* webpackChunkName: "pages/failed-buy" */))
const _55193cf2 = () => interopDefault(import('../pages/failed-invoice.vue' /* webpackChunkName: "pages/failed-invoice" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _c6cd30ea = () => interopDefault(import('../pages/faq/about-vindome.vue' /* webpackChunkName: "pages/faq/about-vindome" */))
const _1a0edf88 = () => interopDefault(import('../pages/faq/billing.vue' /* webpackChunkName: "pages/faq/billing" */))
const _490a9532 = () => interopDefault(import('../pages/faq/buy-and-sell.vue' /* webpackChunkName: "pages/faq/buy-and-sell" */))
const _b0261992 = () => interopDefault(import('../pages/faq/delivery.vue' /* webpackChunkName: "pages/faq/delivery" */))
const _7f699b8f = () => interopDefault(import('../pages/faq/my-account.vue' /* webpackChunkName: "pages/faq/my-account" */))
const _d5c032ac = () => interopDefault(import('../pages/faq/my-portfolio.vue' /* webpackChunkName: "pages/faq/my-portfolio" */))
const _158a00f0 = () => interopDefault(import('../pages/faq/storage.vue' /* webpackChunkName: "pages/faq/storage" */))
const _0a0dd96e = () => interopDefault(import('../pages/faq/using-the-app.vue' /* webpackChunkName: "pages/faq/using-the-app" */))
const _4d4dedb8 = () => interopDefault(import('../pages/faq/wine-investment.vue' /* webpackChunkName: "pages/faq/wine-investment" */))
const _4c34b9c7 = () => interopDefault(import('../pages/faq/_id.vue' /* webpackChunkName: "pages/faq/_id" */))
const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _04bdb0ef = () => interopDefault(import('../pages/gettheapp/index.vue' /* webpackChunkName: "pages/gettheapp/index" */))
const _6d0969a7 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _3466728f = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _665ebeb2 = () => interopDefault(import('../pages/ingrid-brodin.vue' /* webpackChunkName: "pages/ingrid-brodin" */))
const _5d26d9f8 = () => interopDefault(import('../pages/investment-tips.vue' /* webpackChunkName: "pages/investment-tips" */))
const _63d406ad = () => interopDefault(import('../pages/invoices-cart.vue' /* webpackChunkName: "pages/invoices-cart" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6823ea39 = () => interopDefault(import('../pages/login-pending.vue' /* webpackChunkName: "pages/login-pending" */))
const _a18e6c2c = () => interopDefault(import('../pages/lp-activation-2.vue' /* webpackChunkName: "pages/lp-activation-2" */))
const _8bfafdbc = () => interopDefault(import('../pages/lp-brand-message.vue' /* webpackChunkName: "pages/lp-brand-message" */))
const _3dd3b1c7 = () => interopDefault(import('../pages/lp-brand-message-2.vue' /* webpackChunkName: "pages/lp-brand-message-2" */))
const _4f0ba549 = () => interopDefault(import('../pages/lp-campaign-for-investors.vue' /* webpackChunkName: "pages/lp-campaign-for-investors" */))
const _959b00a2 = () => interopDefault(import('../pages/lp-campaign-for-investors-3.vue' /* webpackChunkName: "pages/lp-campaign-for-investors-3" */))
const _5061ddd4 = () => interopDefault(import('../pages/lp-campaign-for-investors-ii.vue' /* webpackChunkName: "pages/lp-campaign-for-investors-ii" */))
const _c5bb0b36 = () => interopDefault(import('../pages/lp-campaign-for-wine-lovers.vue' /* webpackChunkName: "pages/lp-campaign-for-wine-lovers" */))
const _84247c98 = () => interopDefault(import('../pages/lp-christmas-campaign-for-investors.vue' /* webpackChunkName: "pages/lp-christmas-campaign-for-investors" */))
const _49bb82d9 = () => interopDefault(import('../pages/lp-christmas-gifts.vue' /* webpackChunkName: "pages/lp-christmas-gifts" */))
const _35cb2336 = () => interopDefault(import('../pages/lp-collections.vue' /* webpackChunkName: "pages/lp-collections" */))
const _2c07c24a = () => interopDefault(import('../pages/lp-collections-2.vue' /* webpackChunkName: "pages/lp-collections-2" */))
const _2beb9348 = () => interopDefault(import('../pages/lp-collections-3.vue' /* webpackChunkName: "pages/lp-collections-3" */))
const _2bcf6446 = () => interopDefault(import('../pages/lp-collections-4.vue' /* webpackChunkName: "pages/lp-collections-4" */))
const _6e688fb2 = () => interopDefault(import('../pages/lp-en-primeur.vue' /* webpackChunkName: "pages/lp-en-primeur" */))
const _81a988e8 = () => interopDefault(import('../pages/lp-en-primeur-2.vue' /* webpackChunkName: "pages/lp-en-primeur-2" */))
const _79f40013 = () => interopDefault(import('../pages/lp-en-primeur-2021.vue' /* webpackChunkName: "pages/lp-en-primeur-2021" */))
const _818d59e6 = () => interopDefault(import('../pages/lp-en-primeur-3.vue' /* webpackChunkName: "pages/lp-en-primeur-3" */))
const _81712ae4 = () => interopDefault(import('../pages/lp-en-primeur-4.vue' /* webpackChunkName: "pages/lp-en-primeur-4" */))
const _fc759138 = () => interopDefault(import('../pages/lp-en-primeur-II-2021.vue' /* webpackChunkName: "pages/lp-en-primeur-II-2021" */))
const _d39b81d2 = () => interopDefault(import('../pages/lp-en-primeur-purchase.vue' /* webpackChunkName: "pages/lp-en-primeur-purchase" */))
const _65e87308 = () => interopDefault(import('../pages/lp-en-primeur-purchase-2.vue' /* webpackChunkName: "pages/lp-en-primeur-purchase-2" */))
const _65cc4406 = () => interopDefault(import('../pages/lp-en-primeur-purchase-3.vue' /* webpackChunkName: "pages/lp-en-primeur-purchase-3" */))
const _65b01504 = () => interopDefault(import('../pages/lp-en-primeur-purchase-4.vue' /* webpackChunkName: "pages/lp-en-primeur-purchase-4" */))
const _69d1e2b6 = () => interopDefault(import('../pages/lp-en-primeur-purchase-campaign.vue' /* webpackChunkName: "pages/lp-en-primeur-purchase-campaign" */))
const _492bed72 = () => interopDefault(import('../pages/lp-en-primeur-purchase-campaign-II.vue' /* webpackChunkName: "pages/lp-en-primeur-purchase-campaign-II" */))
const _5a0c4a32 = () => interopDefault(import('../pages/lp-invest-registration.vue' /* webpackChunkName: "pages/lp-invest-registration" */))
const _028f76d7 = () => interopDefault(import('../pages/lp-invest-registration-2.vue' /* webpackChunkName: "pages/lp-invest-registration-2" */))
const _029d8e58 = () => interopDefault(import('../pages/lp-invest-registration-3.vue' /* webpackChunkName: "pages/lp-invest-registration-3" */))
const _02aba5d9 = () => interopDefault(import('../pages/lp-invest-registration-4.vue' /* webpackChunkName: "pages/lp-invest-registration-4" */))
const _3a1819cb = () => interopDefault(import('../pages/lp-investment-like-no-other-i.vue' /* webpackChunkName: "pages/lp-investment-like-no-other-i" */))
const _0c09d5ae = () => interopDefault(import('../pages/lp-investment-like-no-other-ii.vue' /* webpackChunkName: "pages/lp-investment-like-no-other-ii" */))
const _784f962b = () => interopDefault(import('../pages/lp-investment-like-no-other-iii.vue' /* webpackChunkName: "pages/lp-investment-like-no-other-iii" */))
const _4894a247 = () => interopDefault(import('../pages/lp-investor.vue' /* webpackChunkName: "pages/lp-investor" */))
const _6bd1600a = () => interopDefault(import('../pages/lp-investor2.vue' /* webpackChunkName: "pages/lp-investor2" */))
const _31dd5f40 = () => interopDefault(import('../pages/lp-live-market-purchase.vue' /* webpackChunkName: "pages/lp-live-market-purchase" */))
const _2a6f8065 = () => interopDefault(import('../pages/lp-live-market-purchase-2.vue' /* webpackChunkName: "pages/lp-live-market-purchase-2" */))
const _74551bbe = () => interopDefault(import('../pages/lp-lover.vue' /* webpackChunkName: "pages/lp-lover" */))
const _71385548 = () => interopDefault(import('../pages/lp-opportunities-for-investors.vue' /* webpackChunkName: "pages/lp-opportunities-for-investors" */))
const _22375e3e = () => interopDefault(import('../pages/lp-opportunities-for-investors-ii.vue' /* webpackChunkName: "pages/lp-opportunities-for-investors-ii" */))
const _602ef1b8 = () => interopDefault(import('../pages/lp-opportunities-for-wine-lovers.vue' /* webpackChunkName: "pages/lp-opportunities-for-wine-lovers" */))
const _0992268c = () => interopDefault(import('../pages/lp-portfolio-performance.vue' /* webpackChunkName: "pages/lp-portfolio-performance" */))
const _2fe0b69e = () => interopDefault(import('../pages/lp-portfolio-performance-2.vue' /* webpackChunkName: "pages/lp-portfolio-performance-2" */))
const _a04a299e = () => interopDefault(import('../pages/lp-thegraceclub-displaystand-learnmore.vue' /* webpackChunkName: "pages/lp-thegraceclub-displaystand-learnmore" */))
const _465060e5 = () => interopDefault(import('../pages/lp-wine-investment-made-easy.vue' /* webpackChunkName: "pages/lp-wine-investment-made-easy" */))
const _3598248d = () => interopDefault(import('../pages/mario-colesanti.vue' /* webpackChunkName: "pages/mario-colesanti" */))
const _09d48279 = () => interopDefault(import('../pages/my-account/index.vue' /* webpackChunkName: "pages/my-account/index" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _58649f7e = () => interopDefault(import('../pages/payment-mango.vue' /* webpackChunkName: "pages/payment-mango" */))
const _166a980e = () => interopDefault(import('../pages/paypal-authorize.vue' /* webpackChunkName: "pages/paypal-authorize" */))
const _9d8c4c84 = () => interopDefault(import('../pages/paypal-fail.vue' /* webpackChunkName: "pages/paypal-fail" */))
const _4fc925da = () => interopDefault(import('../pages/paypal-success.vue' /* webpackChunkName: "pages/paypal-success" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1f17bfa0 = () => interopDefault(import('../pages/registration-active.vue' /* webpackChunkName: "pages/registration-active" */))
const _58599220 = () => interopDefault(import('../pages/registration-device.vue' /* webpackChunkName: "pages/registration-device" */))
const _00b2430a = () => interopDefault(import('../pages/registration-expired.vue' /* webpackChunkName: "pages/registration-expired" */))
const _478637ed = () => interopDefault(import('../pages/registration-pending.vue' /* webpackChunkName: "pages/registration-pending" */))
const _8cdc4f4e = () => interopDefault(import('../pages/registration-success.vue' /* webpackChunkName: "pages/registration-success" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _89102e5a = () => interopDefault(import('../pages/saturnalia-info.vue' /* webpackChunkName: "pages/saturnalia-info" */))
const _56c9dee4 = () => interopDefault(import('../pages/saturnalia-info-iframe.vue' /* webpackChunkName: "pages/saturnalia-info-iframe" */))
const _148c7fb0 = () => interopDefault(import('../pages/start-investing.vue' /* webpackChunkName: "pages/start-investing" */))
const _31c15e45 = () => interopDefault(import('../pages/stripe-refresh.vue' /* webpackChunkName: "pages/stripe-refresh" */))
const _b3047194 = () => interopDefault(import('../pages/stripe-return.vue' /* webpackChunkName: "pages/stripe-return" */))
const _5eb57005 = () => interopDefault(import('../pages/subscribe-en-primeur.vue' /* webpackChunkName: "pages/subscribe-en-primeur" */))
const _2075a2b9 = () => interopDefault(import('../pages/success-bid.vue' /* webpackChunkName: "pages/success-bid" */))
const _3617b7c2 = () => interopDefault(import('../pages/success-buy.vue' /* webpackChunkName: "pages/success-buy" */))
const _dc5bc42c = () => interopDefault(import('../pages/success-buy-full.vue' /* webpackChunkName: "pages/success-buy-full" */))
const _c32f6fdc = () => interopDefault(import('../pages/success-collection.vue' /* webpackChunkName: "pages/success-collection" */))
const _2c42ccff = () => interopDefault(import('../pages/success-collection-delivery.vue' /* webpackChunkName: "pages/success-collection-delivery" */))
const _1ddf3f9a = () => interopDefault(import('../pages/success-collection-full.vue' /* webpackChunkName: "pages/success-collection-full" */))
const _067002b8 = () => interopDefault(import('../pages/success-gift.vue' /* webpackChunkName: "pages/success-gift" */))
const _11125aad = () => interopDefault(import('../pages/success-gift-delivery.vue' /* webpackChunkName: "pages/success-gift-delivery" */))
const _ad43422e = () => interopDefault(import('../pages/success-invoice.vue' /* webpackChunkName: "pages/success-invoice" */))
const _1c176355 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _616a0c1c = () => interopDefault(import('../pages/tell-us-more.vue' /* webpackChunkName: "pages/tell-us-more" */))
const _18f50744 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _f59a091c = () => interopDefault(import('../pages/trading-desk/index.vue' /* webpackChunkName: "pages/trading-desk/index" */))
const _81b0bf02 = () => interopDefault(import('../pages/triplea-success.vue' /* webpackChunkName: "pages/triplea-success" */))
const _91c1d014 = () => interopDefault(import('../pages/valentines-day.vue' /* webpackChunkName: "pages/valentines-day" */))
const _15fea1ae = () => interopDefault(import('../pages/verify-your-identity.vue' /* webpackChunkName: "pages/verify-your-identity" */))
const _dca247dc = () => interopDefault(import('../pages/wine-academy.vue' /* webpackChunkName: "pages/wine-academy" */))
const _32d99a9a = () => interopDefault(import('../pages/wine-collections-investor.vue' /* webpackChunkName: "pages/wine-collections-investor" */))
const _28716208 = () => interopDefault(import('../pages/wine-collections-investor2.vue' /* webpackChunkName: "pages/wine-collections-investor2" */))
const _287f7989 = () => interopDefault(import('../pages/wine-collections-investor3.vue' /* webpackChunkName: "pages/wine-collections-investor3" */))
const _288d910a = () => interopDefault(import('../pages/wine-collections-investor4.vue' /* webpackChunkName: "pages/wine-collections-investor4" */))
const _44fc92ae = () => interopDefault(import('../pages/wine-collections-lover.vue' /* webpackChunkName: "pages/wine-collections-lover" */))
const _7a28e5b3 = () => interopDefault(import('../pages/wine-collector.vue' /* webpackChunkName: "pages/wine-collector" */))
const _69e90be2 = () => interopDefault(import('../pages/wine-collector2.vue' /* webpackChunkName: "pages/wine-collector2" */))
const _509300a5 = () => interopDefault(import('../pages/wine-guide/index.vue' /* webpackChunkName: "pages/wine-guide/index" */))
const _0dfd473c = () => interopDefault(import('../pages/wine-investor.vue' /* webpackChunkName: "pages/wine-investor" */))
const _2741d940 = () => interopDefault(import('../pages/wine-investor2.vue' /* webpackChunkName: "pages/wine-investor2" */))
const _58aaf135 = () => interopDefault(import('../pages/wine-investors-registration.vue' /* webpackChunkName: "pages/wine-investors-registration" */))
const _866a4266 = () => interopDefault(import('../pages/wine-investors-registration2.vue' /* webpackChunkName: "pages/wine-investors-registration2" */))
const _22e203b9 = () => interopDefault(import('../pages/wine-lovers-registration.vue' /* webpackChunkName: "pages/wine-lovers-registration" */))
const _39761cc9 = () => interopDefault(import('../pages/wine-lovers-registration2.vue' /* webpackChunkName: "pages/wine-lovers-registration2" */))
const _308d03cf = () => interopDefault(import('../pages/home/collections.vue' /* webpackChunkName: "pages/home/collections" */))
const _2202ff66 = () => interopDefault(import('../pages/home/enprimeur.vue' /* webpackChunkName: "pages/home/enprimeur" */))
const _7bb0d1a2 = () => interopDefault(import('../pages/home/live.vue' /* webpackChunkName: "pages/home/live" */))
const _74521fa3 = () => interopDefault(import('../pages/lnp/en-primeur.vue' /* webpackChunkName: "pages/lnp/en-primeur" */))
const _731af086 = () => interopDefault(import('../pages/wine-guide/argentina/index.vue' /* webpackChunkName: "pages/wine-guide/argentina/index" */))
const _5cb32116 = () => interopDefault(import('../pages/wine-guide/australia/index.vue' /* webpackChunkName: "pages/wine-guide/australia/index" */))
const _24c43c1a = () => interopDefault(import('../pages/wine-guide/chile/index.vue' /* webpackChunkName: "pages/wine-guide/chile/index" */))
const _d86bfa4a = () => interopDefault(import('../pages/wine-guide/france/index.vue' /* webpackChunkName: "pages/wine-guide/france/index" */))
const _6d9b3b6e = () => interopDefault(import('../pages/wine-guide/germany/index.vue' /* webpackChunkName: "pages/wine-guide/germany/index" */))
const _6624d40e = () => interopDefault(import('../pages/wine-guide/italy/index.vue' /* webpackChunkName: "pages/wine-guide/italy/index" */))
const _5354862a = () => interopDefault(import('../pages/wine-guide/new-zealand/index.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/index" */))
const _42e4d3fe = () => interopDefault(import('../pages/wine-guide/portugal/index.vue' /* webpackChunkName: "pages/wine-guide/portugal/index" */))
const _7c518a8c = () => interopDefault(import('../pages/wine-guide/south-africa/index.vue' /* webpackChunkName: "pages/wine-guide/south-africa/index" */))
const _4b1ff79f = () => interopDefault(import('../pages/wine-guide/spain/index.vue' /* webpackChunkName: "pages/wine-guide/spain/index" */))
const _5c862d99 = () => interopDefault(import('../pages/wine-guide/usa/index.vue' /* webpackChunkName: "pages/wine-guide/usa/index" */))
const _2a57c194 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/index.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/index" */))
const _76aca36e = () => interopDefault(import('../pages/wine-guide/argentina/patagonia/index.vue' /* webpackChunkName: "pages/wine-guide/argentina/patagonia/index" */))
const _7589698e = () => interopDefault(import('../pages/wine-guide/argentina/salta/index.vue' /* webpackChunkName: "pages/wine-guide/argentina/salta/index" */))
const _79647242 = () => interopDefault(import('../pages/wine-guide/australia/barrossa-valley/index.vue' /* webpackChunkName: "pages/wine-guide/australia/barrossa-valley/index" */))
const _ea582660 = () => interopDefault(import('../pages/wine-guide/australia/clare-valley/index.vue' /* webpackChunkName: "pages/wine-guide/australia/clare-valley/index" */))
const _4a36a3ea = () => interopDefault(import('../pages/wine-guide/australia/eden-valley/index.vue' /* webpackChunkName: "pages/wine-guide/australia/eden-valley/index" */))
const _285b5c8b = () => interopDefault(import('../pages/wine-guide/australia/mclaren-valley/index.vue' /* webpackChunkName: "pages/wine-guide/australia/mclaren-valley/index" */))
const _4191c47c = () => interopDefault(import('../pages/wine-guide/australia/yarra-valley/index.vue' /* webpackChunkName: "pages/wine-guide/australia/yarra-valley/index" */))
const _259c0d98 = () => interopDefault(import('../pages/wine-guide/chile/aconcagua/index.vue' /* webpackChunkName: "pages/wine-guide/chile/aconcagua/index" */))
const _15ce5b3b = () => interopDefault(import('../pages/wine-guide/chile/colchagua/index.vue' /* webpackChunkName: "pages/wine-guide/chile/colchagua/index" */))
const _4ecd7610 = () => interopDefault(import('../pages/wine-guide/chile/maipo/index.vue' /* webpackChunkName: "pages/wine-guide/chile/maipo/index" */))
const _49e77848 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/index.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/index" */))
const _efdcde00 = () => interopDefault(import('../pages/wine-guide/france/burgundy/index.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/index" */))
const _5f900368 = () => interopDefault(import('../pages/wine-guide/france/champagne/index.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/index" */))
const _a16f2108 = () => interopDefault(import('../pages/wine-guide/france/cognac.vue' /* webpackChunkName: "pages/wine-guide/france/cognac" */))
const _280887c1 = () => interopDefault(import('../pages/wine-guide/france/jura.vue' /* webpackChunkName: "pages/wine-guide/france/jura" */))
const _6a8a74ca = () => interopDefault(import('../pages/wine-guide/france/languedoc-roussillon.vue' /* webpackChunkName: "pages/wine-guide/france/languedoc-roussillon" */))
const _b650df8a = () => interopDefault(import('../pages/wine-guide/france/provence.vue' /* webpackChunkName: "pages/wine-guide/france/provence" */))
const _ea26e188 = () => interopDefault(import('../pages/wine-guide/france/rhone/index.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/index" */))
const _8c354236 = () => interopDefault(import('../pages/wine-guide/germany/ahr/index.vue' /* webpackChunkName: "pages/wine-guide/germany/ahr/index" */))
const _29a22da8 = () => interopDefault(import('../pages/wine-guide/germany/baden/index.vue' /* webpackChunkName: "pages/wine-guide/germany/baden/index" */))
const _5e647816 = () => interopDefault(import('../pages/wine-guide/germany/franken/index.vue' /* webpackChunkName: "pages/wine-guide/germany/franken/index" */))
const _31040684 = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/index.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/index" */))
const _e303ee58 = () => interopDefault(import('../pages/wine-guide/germany/rheinhessen/index.vue' /* webpackChunkName: "pages/wine-guide/germany/rheinhessen/index" */))
const _6cf5c3ac = () => interopDefault(import('../pages/wine-guide/italy/piedmont/index.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/index" */))
const _7f8c11a5 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/index.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/index" */))
const _1e822acc = () => interopDefault(import('../pages/wine-guide/new-zealand/ata-rangi.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/ata-rangi" */))
const _0e361865 = () => interopDefault(import('../pages/wine-guide/new-zealand/bell-hill-vineyards.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/bell-hill-vineyards" */))
const _695e1862 = () => interopDefault(import('../pages/wine-guide/new-zealand/craggy-range.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/craggy-range" */))
const _6995da59 = () => interopDefault(import('../pages/wine-guide/new-zealand/dog-point-vineyards.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/dog-point-vineyards" */))
const _30ab8472 = () => interopDefault(import('../pages/wine-guide/new-zealand/millton-vineyards-and-winery.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/millton-vineyards-and-winery" */))
const _6e929e54 = () => interopDefault(import('../pages/wine-guide/new-zealand/pyramid-valley-vineyards.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/pyramid-valley-vineyards" */))
const _57a4ab70 = () => interopDefault(import('../pages/wine-guide/new-zealand/rippon-vineyards.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/rippon-vineyards" */))
const _2f40db0a = () => interopDefault(import('../pages/wine-guide/new-zealand/trinity-hill.vue' /* webpackChunkName: "pages/wine-guide/new-zealand/trinity-hill" */))
const _561a62ac = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/index.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/index" */))
const _64e39987 = () => interopDefault(import('../pages/wine-guide/south-africa/constantia/index.vue' /* webpackChunkName: "pages/wine-guide/south-africa/constantia/index" */))
const _1faf9dc9 = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/index.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/index" */))
const _182286ec = () => interopDefault(import('../pages/wine-guide/spain/priorat.vue' /* webpackChunkName: "pages/wine-guide/spain/priorat" */))
const _39b77f51 = () => interopDefault(import('../pages/wine-guide/spain/ribera-del-duero/index.vue' /* webpackChunkName: "pages/wine-guide/spain/ribera-del-duero/index" */))
const _7304af30 = () => interopDefault(import('../pages/wine-guide/usa/napa/index.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/index" */))
const _3dc60f3d = () => interopDefault(import('../pages/wine-guide/usa/sonoma/index.vue' /* webpackChunkName: "pages/wine-guide/usa/sonoma/index" */))
const _7cc308ff = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/achaval-ferrer.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/achaval-ferrer" */))
const _16c71e21 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/bodega-aleanna.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/bodega-aleanna" */))
const _38ee3341 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/bodega-alta-vista.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/bodega-alta-vista" */))
const _50c0e152 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/bodega-catena-zapata.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/bodega-catena-zapata" */))
const _0110cbe0 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/bodega-luigi-bosca.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/bodega-luigi-bosca" */))
const _e52f5846 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/familia-zuccardi.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/familia-zuccardi" */))
const _3befb8be = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/michel-rolland.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/michel-rolland" */))
const _0f91c988 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/rutini-wines.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/rutini-wines" */))
const _31c18090 = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/trapiche.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/trapiche" */))
const _3902a8bf = () => interopDefault(import('../pages/wine-guide/argentina/mendoza/vina-cobos.vue' /* webpackChunkName: "pages/wine-guide/argentina/mendoza/vina-cobos" */))
const _f350e956 = () => interopDefault(import('../pages/wine-guide/argentina/patagonia/bodega-noemia-patagonia.vue' /* webpackChunkName: "pages/wine-guide/argentina/patagonia/bodega-noemia-patagonia" */))
const _6f99eb4c = () => interopDefault(import('../pages/wine-guide/argentina/patagonia/bodegas-chacra.vue' /* webpackChunkName: "pages/wine-guide/argentina/patagonia/bodegas-chacra" */))
const _6d258f25 = () => interopDefault(import('../pages/wine-guide/argentina/salta/bodega-el-esterco.vue' /* webpackChunkName: "pages/wine-guide/argentina/salta/bodega-el-esterco" */))
const _2542c174 = () => interopDefault(import('../pages/wine-guide/argentina/salta/bodegas-etchart.vue' /* webpackChunkName: "pages/wine-guide/argentina/salta/bodegas-etchart" */))
const _7b3b16c4 = () => interopDefault(import('../pages/wine-guide/argentina/salta/finca-quara-winery.vue' /* webpackChunkName: "pages/wine-guide/argentina/salta/finca-quara-winery" */))
const _9a24eb4e = () => interopDefault(import('../pages/wine-guide/australia/barrossa-valley/penfolds.vue' /* webpackChunkName: "pages/wine-guide/australia/barrossa-valley/penfolds" */))
const _1002c0bd = () => interopDefault(import('../pages/wine-guide/australia/barrossa-valley/torbrek.vue' /* webpackChunkName: "pages/wine-guide/australia/barrossa-valley/torbrek" */))
const _c1332654 = () => interopDefault(import('../pages/wine-guide/australia/clare-valley/jim-barry-wines.vue' /* webpackChunkName: "pages/wine-guide/australia/clare-valley/jim-barry-wines" */))
const _c0e63c50 = () => interopDefault(import('../pages/wine-guide/australia/eden-valley/henschke.vue' /* webpackChunkName: "pages/wine-guide/australia/eden-valley/henschke" */))
const _4e1f0092 = () => interopDefault(import('../pages/wine-guide/australia/mclaren-valley/fox-creek.vue' /* webpackChunkName: "pages/wine-guide/australia/mclaren-valley/fox-creek" */))
const _13a5fa62 = () => interopDefault(import('../pages/wine-guide/australia/yarra-valley/mount-mary.vue' /* webpackChunkName: "pages/wine-guide/australia/yarra-valley/mount-mary" */))
const _0b61a2a2 = () => interopDefault(import('../pages/wine-guide/chile/aconcagua/sena.vue' /* webpackChunkName: "pages/wine-guide/chile/aconcagua/sena" */))
const _61995da2 = () => interopDefault(import('../pages/wine-guide/chile/aconcagua/vina-errazuriz.vue' /* webpackChunkName: "pages/wine-guide/chile/aconcagua/vina-errazuriz" */))
const _5095085f = () => interopDefault(import('../pages/wine-guide/chile/aconcagua/vina-von-sibenthal.vue' /* webpackChunkName: "pages/wine-guide/chile/aconcagua/vina-von-sibenthal" */))
const _643dd6c0 = () => interopDefault(import('../pages/wine-guide/chile/colchagua/montes-folly.vue' /* webpackChunkName: "pages/wine-guide/chile/colchagua/montes-folly" */))
const _7622e2b8 = () => interopDefault(import('../pages/wine-guide/chile/maipo/almaviva-wines.vue' /* webpackChunkName: "pages/wine-guide/chile/maipo/almaviva-wines" */))
const _16f92903 = () => interopDefault(import('../pages/wine-guide/chile/maipo/concha-y-toto.vue' /* webpackChunkName: "pages/wine-guide/chile/maipo/concha-y-toto" */))
const _693b4010 = () => interopDefault(import('../pages/wine-guide/chile/maipo/don-melchor.vue' /* webpackChunkName: "pages/wine-guide/chile/maipo/don-melchor" */))
const _5d2388ea = () => interopDefault(import('../pages/wine-guide/france/bordeaux/alter-ego-de-palmer.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/alter-ego-de-palmer" */))
const _ca2cd6ac = () => interopDefault(import('../pages/wine-guide/france/bordeaux/carillon-dangelus.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/carillon-dangelus" */))
const _fa2adb74 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/carruades-de-lafite.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/carruades-de-lafite" */))
const _393889ee = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-angelus.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-angelus" */))
const _517dbe8d = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-ausone.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-ausone" */))
const _cffb373c = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-belgrave.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-belgrave" */))
const _b75a3f88 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-calon-segur.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-calon-segur" */))
const _25c14df8 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-cantemerle.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-cantemerle" */))
const _0cf2e9de = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-cantenac-brown.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-cantenac-brown" */))
const _0deef827 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-climens.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-climens" */))
const _9d22fe7a = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-clinet.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-clinet" */))
const _788baf92 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-cos-destournel.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-cos-destournel" */))
const _087834d6 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-cos-labory.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-cos-labory" */))
const _eba16880 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-coutet.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-coutet" */))
const _465572da = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-de-camensac.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-de-camensac" */))
const _54e58ab2 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-de-ferrand.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-de-ferrand" */))
const _2a1a8fe8 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-de-fieuzal.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-de-fieuzal" */))
const _4fab60ac = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-ducru-beaucaillou.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-ducru-beaucaillou" */))
const _4542dd94 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-dyquem-bordeaux.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-dyquem-bordeaux" */))
const _1de02609 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-figeac.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-figeac" */))
const _5c5fc01e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-fleur-cardinale.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-fleur-cardinale" */))
const _102602d0 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-fombrauge.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-fombrauge" */))
const _5348c71f = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-giscours.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-giscours" */))
const _63e0ce13 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-grand-puy-lacoste.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-grand-puy-lacoste" */))
const _2fff9f95 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-gruaud-larose.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-gruaud-larose" */))
const _33b4b317 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-haut-bages-liberal.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-haut-bages-liberal" */))
const _47f6317c = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-haut-batailley.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-haut-batailley" */))
const _65e4cf49 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-haut-brion.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-haut-brion" */))
const _6cbdb3d7 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-haut-marbuzet.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-haut-marbuzet" */))
const _12c77656 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-kirwan.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-kirwan" */))
const _78341744 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-la-fleur-petrus.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-la-fleur-petrus" */))
const _c45cf216 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-la-gurgue.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-la-gurgue" */))
const _4a45e5e6 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-la-mission-haut-brion.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-la-mission-haut-brion" */))
const _7a6f4068 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-la-tour-carnet.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-la-tour-carnet" */))
const _5bfb76c8 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-lafite-rothschild.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-lafite-rothschild" */))
const _49f4169e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-lagrange.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-lagrange" */))
const _4e24793f = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-lanessan.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-lanessan" */))
const _67f922b7 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-langoa-barton.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-langoa-barton" */))
const _444ad12e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-larrivet-haut-brion.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-larrivet-haut-brion" */))
const _a0518cda = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-latour.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-latour" */))
const _7c0256bb = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-latour-martillac.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-latour-martillac" */))
const _aa99f40a = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-leoville-barton.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-leoville-barton" */))
const _f00585ee = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-leoville-las-cases.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-leoville-las-cases" */))
const _244d79c7 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-leoville-poyferre.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-leoville-poyferre" */))
const _3d4f4459 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-les-grands-chenes.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-les-grands-chenes" */))
const _4dfb510f = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-levangile.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-levangile" */))
const _69db1a79 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-lynch-bages.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-lynch-bages" */))
const _624cb7e7 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-malescasse.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-malescasse" */))
const _5f766605 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-margaux.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-margaux" */))
const _402e76de = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-marquis-dalesme.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-marquis-dalesme" */))
const _6ebba614 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-maucaillou.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-maucaillou" */))
const _28f19598 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-monbousquet.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-monbousquet" */))
const _0d18b3fd = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-montrose.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-montrose" */))
const _2503e41e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-mouton-rothschild.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-mouton-rothschild" */))
const _864cd2b6 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-palmer.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-palmer" */))
const _74b4a019 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pape-clement.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pape-clement" */))
const _c6f096aa = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pavie.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pavie" */))
const _475736a4 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pedesclaux.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pedesclaux" */))
const _70cf8357 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-petrus.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-petrus" */))
const _36214d12 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-phelan-segur.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-phelan-segur" */))
const _528d967b = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pichon-lalande.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pichon-lalande" */))
const _ccdcb83e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pichon-longueville-baron.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pichon-longueville-baron" */))
const _63761ba8 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pontet-canet.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pontet-canet" */))
const _42390806 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-pouget.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-pouget" */))
const _3e1b5e4e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-poujeaux.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-poujeaux" */))
const _4bcef544 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-rauzan-segla.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-rauzan-segla" */))
const _5143752a = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-rieussec.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-rieussec" */))
const _14a98942 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-sigalas-rabaud.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-sigalas-rabaud" */))
const _1ec1d858 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-taillefer.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-taillefer" */))
const _5947748e = () => interopDefault(import('../pages/wine-guide/france/bordeaux/chateau-talbot.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/chateau-talbot" */))
const _f6b9ac0c = () => interopDefault(import('../pages/wine-guide/france/bordeaux/cheval-blanc.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/cheval-blanc" */))
const _6dec3cbe = () => interopDefault(import('../pages/wine-guide/france/bordeaux/echo-lynch-bages.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/echo-lynch-bages" */))
const _29a0514d = () => interopDefault(import('../pages/wine-guide/france/bordeaux/le-petit-mouton.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/le-petit-mouton" */))
const _39584edc = () => interopDefault(import('../pages/wine-guide/france/bordeaux/marquis-de-calon.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/marquis-de-calon" */))
const _21e0c6a9 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/pagodes-de-cos.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/pagodes-de-cos" */))
const _4faa4cd5 = () => interopDefault(import('../pages/wine-guide/france/bordeaux/petit-haut-lafitte.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/petit-haut-lafitte" */))
const _03abc4af = () => interopDefault(import('../pages/wine-guide/france/bordeaux/vieux-chateau-certan.vue' /* webpackChunkName: "pages/wine-guide/france/bordeaux/vieux-chateau-certan" */))
const _7a1017a8 = () => interopDefault(import('../pages/wine-guide/france/burgundy/chateau-batard.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/chateau-batard" */))
const _4c49e75d = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-alain-hudelot-noellat.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-alain-hudelot-noellat" */))
const _75201796 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-armand-rousseau.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-armand-rousseau" */))
const _7548c4e6 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-bruno-colinv.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-bruno-colinv" */))
const _cc7e43b0 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-claude-dugat.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-claude-dugat" */))
const _5372da7f = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-coche-dury.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-coche-dury" */))
const _7c6dbb66 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-compte-georges-vogue.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-compte-georges-vogue" */))
const _3ac4065c = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-comtes-lafon.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-comtes-lafon" */))
const _1097e9b8 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-confuron-cotetidot.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-confuron-cotetidot" */))
const _694670ca = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-dugat-py.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-dugat-py" */))
const _6c5e6edd = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-dujac.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-dujac" */))
const _5a2c137a = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-emmanuel-rouget.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-emmanuel-rouget" */))
const _308cc17a = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-etienne-sauzet.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-etienne-sauzet" */))
const _26c3bdb1 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-faiveley.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-faiveley" */))
const _0ad999c0 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-georges-noellat.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-georges-noellat" */))
const _62529cca = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-georges-roumier.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-georges-roumier" */))
const _ff1ea2fa = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-harmand-geoffroy.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-harmand-geoffroy" */))
const _da968844 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-henri-boillot.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-henri-boillot" */))
const _033f0725 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-hubert-lignier.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-hubert-lignier" */))
const _5e0dfc03 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-jean-tardy.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-jean-tardy" */))
const _28b15d6e = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-joseph-drouhin.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-joseph-drouhin" */))
const _39f0abf6 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-lignier-michelot.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-lignier-michelot" */))
const _c1c39fba = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-louis-jadot.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-louis-jadot" */))
const _d27ef58e = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-mark-haisma.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-mark-haisma" */))
const _67580eef = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-meo-camuzet.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-meo-camuzet" */))
const _0dcb9d3d = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-paul-pernot.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-paul-pernot" */))
const _28386824 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-ramonet.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-ramonet" */))
const _3fda1237 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-raveneau.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-raveneau" */))
const _07b3c91f = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-rene-lequin-colin.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-rene-lequin-colin" */))
const _70fc711f = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-robert-groffier.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-robert-groffier" */))
const _89911958 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-stephane-magnien.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-stephane-magnien" */))
const _3596875c = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-terres-dorees.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-terres-dorees" */))
const _37c3c0dc = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-vincent-bouzereau.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-vincent-bouzereau" */))
const _21c36bd6 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-vincent-dauvissat.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-vincent-dauvissat" */))
const _5054b582 = () => interopDefault(import('../pages/wine-guide/france/burgundy/domaine-william-fevre.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/domaine-william-fevre" */))
const _daffff68 = () => interopDefault(import('../pages/wine-guide/france/burgundy/jean-marc-boillot.vue' /* webpackChunkName: "pages/wine-guide/france/burgundy/jean-marc-boillot" */))
const _331fcd38 = () => interopDefault(import('../pages/wine-guide/france/champagne/bollinger.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/bollinger" */))
const _55e33912 = () => interopDefault(import('../pages/wine-guide/france/champagne/dom-perignon.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/dom-perignon" */))
const _370b5393 = () => interopDefault(import('../pages/wine-guide/france/champagne/krug.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/krug" */))
const _6493ab3a = () => interopDefault(import('../pages/wine-guide/france/champagne/laurent-perrier.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/laurent-perrier" */))
const _70ea9241 = () => interopDefault(import('../pages/wine-guide/france/champagne/louis-roederer.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/louis-roederer" */))
const _c375b764 = () => interopDefault(import('../pages/wine-guide/france/champagne/philipponnat.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/philipponnat" */))
const _82ff34e6 = () => interopDefault(import('../pages/wine-guide/france/champagne/pol-roger.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/pol-roger" */))
const _35f00d5e = () => interopDefault(import('../pages/wine-guide/france/champagne/ruinart.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/ruinart" */))
const _c8fb115a = () => interopDefault(import('../pages/wine-guide/france/champagne/salon.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/salon" */))
const _5f8d3d4d = () => interopDefault(import('../pages/wine-guide/france/champagne/taittinger.vue' /* webpackChunkName: "pages/wine-guide/france/champagne/taittinger" */))
const _05674d6f = () => interopDefault(import('../pages/wine-guide/france/rhone/chateau-beaucastel.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/chateau-beaucastel" */))
const _14aaa90c = () => interopDefault(import('../pages/wine-guide/france/rhone/chateau-rayas.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/chateau-rayas" */))
const _8576f150 = () => interopDefault(import('../pages/wine-guide/france/rhone/clos-des-papes.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/clos-des-papes" */))
const _0571f586 = () => interopDefault(import('../pages/wine-guide/france/rhone/delas-freres.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/delas-freres" */))
const _7311a64d = () => interopDefault(import('../pages/wine-guide/france/rhone/domaine-de-la-janasse.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/domaine-de-la-janasse" */))
const _25e6e130 = () => interopDefault(import('../pages/wine-guide/france/rhone/etienne-guigal.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/etienne-guigal" */))
const _c7ce88de = () => interopDefault(import('../pages/wine-guide/france/rhone/henri-bonneau.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/henri-bonneau" */))
const _831e68ca = () => interopDefault(import('../pages/wine-guide/france/rhone/jean-louis-chave.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/jean-louis-chave" */))
const _5a06e3fc = () => interopDefault(import('../pages/wine-guide/france/rhone/m-chapoutier.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/m-chapoutier" */))
const _0920d77a = () => interopDefault(import('../pages/wine-guide/france/rhone/paul-jaboulet-aine.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/paul-jaboulet-aine" */))
const _b401844e = () => interopDefault(import('../pages/wine-guide/france/rhone/pierre-usseglio.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/pierre-usseglio" */))
const _18d5f428 = () => interopDefault(import('../pages/wine-guide/france/rhone/rene-rostaing.vue' /* webpackChunkName: "pages/wine-guide/france/rhone/rene-rostaing" */))
const _63930222 = () => interopDefault(import('../pages/wine-guide/germany/ahr/jean-stodden.vue' /* webpackChunkName: "pages/wine-guide/germany/ahr/jean-stodden" */))
const _a9980cce = () => interopDefault(import('../pages/wine-guide/germany/baden/bernhard-huber.vue' /* webpackChunkName: "pages/wine-guide/germany/baden/bernhard-huber" */))
const _768f344c = () => interopDefault(import('../pages/wine-guide/germany/franken/rudolf-furst.vue' /* webpackChunkName: "pages/wine-guide/germany/franken/rudolf-furst" */))
const _3fad7d3e = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert" */))
const _6365b3d3 = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/egon-muller.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/egon-muller" */))
const _ca0f458e = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/jj-prum.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/jj-prum" */))
const _1db3287e = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/peter-lauer.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/peter-lauer" */))
const _591fa295 = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/van-volxem.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/van-volxem" */))
const _d666267a = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken" */))
const _045f88b6 = () => interopDefault(import('../pages/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch.vue' /* webpackChunkName: "pages/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch" */))
const _5e91f3f6 = () => interopDefault(import('../pages/wine-guide/germany/rheinhessen/klaus-peter-keller.vue' /* webpackChunkName: "pages/wine-guide/germany/rheinhessen/klaus-peter-keller" */))
const _90c77a06 = () => interopDefault(import('../pages/wine-guide/germany/rheinhessen/von-buhl.vue' /* webpackChunkName: "pages/wine-guide/germany/rheinhessen/von-buhl" */))
const _c44b16a8 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/bruno-giacosa.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/bruno-giacosa" */))
const _747ffd5f = () => interopDefault(import('../pages/wine-guide/italy/piedmont/cantina-roberto-voerzio.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/cantina-roberto-voerzio" */))
const _f3d5a19c = () => interopDefault(import('../pages/wine-guide/italy/piedmont/conterno-fantino.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/conterno-fantino" */))
const _77ea5ad9 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/g-d-vajra.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/g-d-vajra" */))
const _43a1fc27 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/gaja.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/gaja" */))
const _1da6a014 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/giacomo-conterno.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/giacomo-conterno" */))
const _5a6ec4a6 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio" */))
const _526710d3 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/massolino.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/massolino" */))
const _0648a4d2 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/poderi-aldo-conterno.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/poderi-aldo-conterno" */))
const _ff3ae2a8 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/roagna.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/roagna" */))
const _6a72c5ce = () => interopDefault(import('../pages/wine-guide/italy/piedmont/tenuta-la-giustiniana.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/tenuta-la-giustiniana" */))
const _af3117f4 = () => interopDefault(import('../pages/wine-guide/italy/piedmont/vigne-marina-coppi.vue' /* webpackChunkName: "pages/wine-guide/italy/piedmont/vigne-marina-coppi" */))
const _57aee5c4 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/avignonesi.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/avignonesi" */))
const _4ec214a8 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri" */))
const _bb2e8026 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/azienda-salvioni.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/azienda-salvioni" */))
const _ee66c4fc = () => interopDefault(import('../pages/wine-guide/italy/tuscany/castiglione-del-bosco.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/castiglione-del-bosco" */))
const _30b72b6c = () => interopDefault(import('../pages/wine-guide/italy/tuscany/conti-constani.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/conti-constani" */))
const _2ae9219d = () => interopDefault(import('../pages/wine-guide/italy/tuscany/fattoria-le-pupille.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/fattoria-le-pupille" */))
const _764af1f9 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/fattoria-poggio-di-sotto.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/fattoria-poggio-di-sotto" */))
const _627dff18 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/fontodi.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/fontodi" */))
const _1cbff03f = () => interopDefault(import('../pages/wine-guide/italy/tuscany/frescobaldi.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/frescobaldi" */))
const _327ac738 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/gianfranco-soldera.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/gianfranco-soldera" */))
const _aa82ed92 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/isole-e-olena.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/isole-e-olena" */))
const _7cc7fdc2 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/marchesi-antinori.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/marchesi-antinori" */))
const _0bffdff4 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/podere-poggio-scalette.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/podere-poggio-scalette" */))
const _26b544ca = () => interopDefault(import('../pages/wine-guide/italy/tuscany/poderi-boscarelli.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/poderi-boscarelli" */))
const _0d428c02 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/rocca-di-frassinello.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/rocca-di-frassinello" */))
const _acbefe64 = () => interopDefault(import('../pages/wine-guide/italy/tuscany/tenuta-dell-ornellaia.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/tenuta-dell-ornellaia" */))
const _412f29dc = () => interopDefault(import('../pages/wine-guide/italy/tuscany/tenuta-di-trinoro.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/tenuta-di-trinoro" */))
const _2fff9f6e = () => interopDefault(import('../pages/wine-guide/italy/tuscany/tenuta-san-guido.vue' /* webpackChunkName: "pages/wine-guide/italy/tuscany/tenuta-san-guido" */))
const _73c94402 = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/casa-ferreirinha.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/casa-ferreirinha" */))
const _657099ad = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/howards-folly.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/howards-folly" */))
const _1d4e2226 = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/prats-and-symington.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/prats-and-symington" */))
const _713d919d = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/quinta-do-crasto.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/quinta-do-crasto" */))
const _71093b1e = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/quinta-do-vale-meao.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/quinta-do-vale-meao" */))
const _15ed7cb0 = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/quinta-do-vallado.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/quinta-do-vallado" */))
const _0970150c = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/quinta-do-vesuvio.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/quinta-do-vesuvio" */))
const _2661e6bf = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/roquette-cazes-quinta.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/roquette-cazes-quinta" */))
const _84f78cdc = () => interopDefault(import('../pages/wine-guide/portugal/douro-valley/symington.vue' /* webpackChunkName: "pages/wine-guide/portugal/douro-valley/symington" */))
const _5ddf31c7 = () => interopDefault(import('../pages/wine-guide/south-africa/constantia/restless-river.vue' /* webpackChunkName: "pages/wine-guide/south-africa/constantia/restless-river" */))
const _840db0fc = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/alto-wine-estate.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/alto-wine-estate" */))
const _80b8f8c4 = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer" */))
const _8b721cbe = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/meerlust-estate.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/meerlust-estate" */))
const _537ac37c = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/savanha-wines.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/savanha-wines" */))
const _715e7c51 = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/the-sadie-family-wines.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/the-sadie-family-wines" */))
const _64646ed7 = () => interopDefault(import('../pages/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards.vue' /* webpackChunkName: "pages/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards" */))
const _3d09a621 = () => interopDefault(import('../pages/wine-guide/spain/ribera-del-duero/pingus.vue' /* webpackChunkName: "pages/wine-guide/spain/ribera-del-duero/pingus" */))
const _3a77f395 = () => interopDefault(import('../pages/wine-guide/spain/ribera-del-duero/vega-sicilia.vue' /* webpackChunkName: "pages/wine-guide/spain/ribera-del-duero/vega-sicilia" */))
const _f48e68fe = () => interopDefault(import('../pages/wine-guide/usa/napa/abreu-vineyards.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/abreu-vineyards" */))
const _2da3c4a4 = () => interopDefault(import('../pages/wine-guide/usa/napa/caymus-vineyards.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/caymus-vineyards" */))
const _79ed6ebf = () => interopDefault(import('../pages/wine-guide/usa/napa/christian-moueix-dominus.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/christian-moueix-dominus" */))
const _182dc051 = () => interopDefault(import('../pages/wine-guide/usa/napa/colgin-cellars.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/colgin-cellars" */))
const _09ae85cc = () => interopDefault(import('../pages/wine-guide/usa/napa/harlan-estates.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/harlan-estates" */))
const _1f5a0243 = () => interopDefault(import('../pages/wine-guide/usa/napa/kapcsandy-winery.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/kapcsandy-winery" */))
const _6b8e0fea = () => interopDefault(import('../pages/wine-guide/usa/napa/opus-one.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/opus-one" */))
const _3e17ba93 = () => interopDefault(import('../pages/wine-guide/usa/napa/robert-mondovi-vineyards.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/robert-mondovi-vineyards" */))
const _652bbbb6 = () => interopDefault(import('../pages/wine-guide/usa/napa/scarecrow-wines.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/scarecrow-wines" */))
const _37751622 = () => interopDefault(import('../pages/wine-guide/usa/napa/schrader-cellars.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/schrader-cellars" */))
const _28919fb0 = () => interopDefault(import('../pages/wine-guide/usa/napa/screaming-eagle.vue' /* webpackChunkName: "pages/wine-guide/usa/napa/screaming-eagle" */))
const _ab6040d0 = () => interopDefault(import('../pages/wine-guide/usa/sonoma/ridge-vineyards.vue' /* webpackChunkName: "pages/wine-guide/usa/sonoma/ridge-vineyards" */))
const _c5845f3c = () => interopDefault(import('../pages/wine-guide/usa/sonoma/sine-qua-non.vue' /* webpackChunkName: "pages/wine-guide/usa/sonoma/sine-qua-non" */))
const _5b55c9c7 = () => interopDefault(import('../pages/wine-guide/usa/sonoma/verite-vigneron.vue' /* webpackChunkName: "pages/wine-guide/usa/sonoma/verite-vigneron" */))
const _6acfc9b4 = () => interopDefault(import('../pages/collection-details/_collectionId.vue' /* webpackChunkName: "pages/collection-details/_collectionId" */))
const _6d2421dc = () => interopDefault(import('../pages/gettheapp/_uk.vue' /* webpackChunkName: "pages/gettheapp/_uk" */))
const _50c4b906 = () => interopDefault(import('../pages/my-account/_preselectedTab.vue' /* webpackChunkName: "pages/my-account/_preselectedTab" */))
const _a828d138 = () => interopDefault(import('../pages/trading-desk/_EnPrimeur.vue' /* webpackChunkName: "pages/trading-desk/_EnPrimeur" */))
const _5640e214 = () => interopDefault(import('../pages/wine-trading-buy/_wineId.vue' /* webpackChunkName: "pages/wine-trading-buy/_wineId" */))
const _fed30148 = () => interopDefault(import('../pages/wine-trading-sell/_wineId.vue' /* webpackChunkName: "pages/wine-trading-sell/_wineId" */))
const _01cb925b = () => interopDefault(import('../pages/wine-details/_wineId/_caseSizeId/index.vue' /* webpackChunkName: "pages/wine-details/_wineId/_caseSizeId/index" */))
const _6472366e = () => interopDefault(import('../pages/wine-performance/_wineId/_caseSizeId/index.vue' /* webpackChunkName: "pages/wine-performance/_wineId/_caseSizeId/index" */))
const _3c80641c = () => interopDefault(import('../pages/wine-details/_wineId/_caseSizeId/_wineName.vue' /* webpackChunkName: "pages/wine-details/_wineId/_caseSizeId/_wineName" */))
const _1c90cf8a = () => interopDefault(import('../pages/wine-performance/_wineId/_caseSizeId/_wineName.vue' /* webpackChunkName: "pages/wine-performance/_wineId/_caseSizeId/_wineName" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about___en"
  }, {
    path: "/about-us",
    component: _e9b98470,
    name: "about-us___en"
  }, {
    path: "/activate-gift",
    component: _698c8d60,
    name: "activate-gift___en"
  }, {
    path: "/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___en"
  }, {
    path: "/app",
    component: _a81b68b2,
    name: "app___en"
  }, {
    path: "/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___en"
  }, {
    path: "/appscreens",
    component: _0030ccb0,
    name: "appscreens___en"
  }, {
    path: "/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___en"
  }, {
    path: "/barcode",
    component: _26e5e8f4,
    name: "barcode___en"
  }, {
    path: "/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___en"
  }, {
    path: "/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___en"
  }, {
    path: "/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___en"
  }, {
    path: "/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___en"
  }, {
    path: "/collections",
    component: _4e64555e,
    name: "collections___en"
  }, {
    path: "/collections-cart",
    component: _78c65e42,
    name: "collections-cart___en"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us___en"
  }, {
    path: "/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___en"
  }, {
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___en"
  }, {
    path: "/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___en"
  }, {
    path: "/enter-amount",
    component: _6777a957,
    name: "enter-amount___en"
  }, {
    path: "/es",
    component: _2dfb1658,
    name: "index___es"
  }, {
    path: "/failed-bid",
    component: _d97eb752,
    name: "failed-bid___en"
  }, {
    path: "/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___en"
  }, {
    path: "/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___en"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq___en",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___en"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___en"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___en"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___en"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___en"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___en"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___en"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___en"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___en"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___en"
    }]
  }, {
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___en"
  }, {
    path: "/fr",
    component: _2dfb1658,
    name: "index___fr"
  }, {
    path: "/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___en"
  }, {
    path: "/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___en"
  }, {
    path: "/imprint",
    component: _3466728f,
    name: "imprint___en"
  }, {
    path: "/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___en"
  }, {
    path: "/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___en"
  }, {
    path: "/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___en"
  }, {
    path: "/it",
    component: _2dfb1658,
    name: "index___it"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/login-pending",
    component: _6823ea39,
    name: "login-pending___en"
  }, {
    path: "/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___en"
  }, {
    path: "/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___en"
  }, {
    path: "/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___en"
  }, {
    path: "/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___en"
  }, {
    path: "/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___en"
  }, {
    path: "/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___en"
  }, {
    path: "/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___en"
  }, {
    path: "/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___en"
  }, {
    path: "/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___en"
  }, {
    path: "/lp-collections",
    component: _35cb2336,
    name: "lp-collections___en"
  }, {
    path: "/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___en"
  }, {
    path: "/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___en"
  }, {
    path: "/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___en"
  }, {
    path: "/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___en"
  }, {
    path: "/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___en"
  }, {
    path: "/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___en"
  }, {
    path: "/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___en"
  }, {
    path: "/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___en"
  }, {
    path: "/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___en"
  }, {
    path: "/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___en"
  }, {
    path: "/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___en"
  }, {
    path: "/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___en"
  }, {
    path: "/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___en"
  }, {
    path: "/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___en"
  }, {
    path: "/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___en"
  }, {
    path: "/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___en"
  }, {
    path: "/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___en"
  }, {
    path: "/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___en"
  }, {
    path: "/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___en"
  }, {
    path: "/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___en"
  }, {
    path: "/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___en"
  }, {
    path: "/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___en"
  }, {
    path: "/lp-investor",
    component: _4894a247,
    name: "lp-investor___en"
  }, {
    path: "/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___en"
  }, {
    path: "/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___en"
  }, {
    path: "/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___en"
  }, {
    path: "/lp-lover",
    component: _74551bbe,
    name: "lp-lover___en"
  }, {
    path: "/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___en"
  }, {
    path: "/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___en"
  }, {
    path: "/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___en"
  }, {
    path: "/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___en"
  }, {
    path: "/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___en"
  }, {
    path: "/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___en"
  }, {
    path: "/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___en"
  }, {
    path: "/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___en"
  }, {
    path: "/my-account",
    component: _09d48279,
    name: "my-account___en"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news___en"
  }, {
    path: "/notifications",
    component: _5172b96e,
    name: "notifications___en"
  }, {
    path: "/payment-mango",
    component: _58649f7e,
    name: "payment-mango___en"
  }, {
    path: "/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___en"
  }, {
    path: "/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___en"
  }, {
    path: "/paypal-success",
    component: _4fc925da,
    name: "paypal-success___en"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___en"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/registration-active",
    component: _1f17bfa0,
    name: "registration-active___en"
  }, {
    path: "/registration-device",
    component: _58599220,
    name: "registration-device___en"
  }, {
    path: "/registration-expired",
    component: _00b2430a,
    name: "registration-expired___en"
  }, {
    path: "/registration-pending",
    component: _478637ed,
    name: "registration-pending___en"
  }, {
    path: "/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___en"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password___en"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___en"
  }, {
    path: "/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___en"
  }, {
    path: "/start-investing",
    component: _148c7fb0,
    name: "start-investing___en"
  }, {
    path: "/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___en"
  }, {
    path: "/stripe-return",
    component: _b3047194,
    name: "stripe-return___en"
  }, {
    path: "/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___en"
  }, {
    path: "/success-bid",
    component: _2075a2b9,
    name: "success-bid___en"
  }, {
    path: "/success-buy",
    component: _3617b7c2,
    name: "success-buy___en"
  }, {
    path: "/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___en"
  }, {
    path: "/success-collection",
    component: _c32f6fdc,
    name: "success-collection___en"
  }, {
    path: "/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___en"
  }, {
    path: "/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___en"
  }, {
    path: "/success-gift",
    component: _067002b8,
    name: "success-gift___en"
  }, {
    path: "/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___en"
  }, {
    path: "/success-invoice",
    component: _ad43422e,
    name: "success-invoice___en"
  }, {
    path: "/support",
    component: _1c176355,
    name: "support___en"
  }, {
    path: "/sv",
    component: _2dfb1658,
    name: "index___sv"
  }, {
    path: "/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___en"
  }, {
    path: "/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___en"
  }, {
    path: "/trading-desk",
    component: _f59a091c,
    name: "trading-desk___en"
  }, {
    path: "/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___en"
  }, {
    path: "/valentines-day",
    component: _91c1d014,
    name: "valentines-day___en"
  }, {
    path: "/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___en"
  }, {
    path: "/wine-academy",
    component: _dca247dc,
    name: "wine-academy___en"
  }, {
    path: "/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___en"
  }, {
    path: "/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___en"
  }, {
    path: "/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___en"
  }, {
    path: "/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___en"
  }, {
    path: "/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___en"
  }, {
    path: "/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___en"
  }, {
    path: "/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___en"
  }, {
    path: "/wine-guide",
    component: _509300a5,
    name: "wine-guide___en"
  }, {
    path: "/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___en"
  }, {
    path: "/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___en"
  }, {
    path: "/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___en"
  }, {
    path: "/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___en"
  }, {
    path: "/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___en"
  }, {
    path: "/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___en"
  }, {
    path: "/de/about",
    component: _0e433b93,
    name: "about___de"
  }, {
    path: "/de/about-us",
    component: _e9b98470,
    name: "about-us___de"
  }, {
    path: "/de/activate-gift",
    component: _698c8d60,
    name: "activate-gift___de"
  }, {
    path: "/de/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___de"
  }, {
    path: "/de/app",
    component: _a81b68b2,
    name: "app___de"
  }, {
    path: "/de/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___de"
  }, {
    path: "/de/appscreens",
    component: _0030ccb0,
    name: "appscreens___de"
  }, {
    path: "/de/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___de"
  }, {
    path: "/de/barcode",
    component: _26e5e8f4,
    name: "barcode___de"
  }, {
    path: "/de/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___de"
  }, {
    path: "/de/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___de"
  }, {
    path: "/de/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___de"
  }, {
    path: "/de/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___de"
  }, {
    path: "/de/collections",
    component: _4e64555e,
    name: "collections___de"
  }, {
    path: "/de/collections-cart",
    component: _78c65e42,
    name: "collections-cart___de"
  }, {
    path: "/de/contact-us",
    component: _521bf555,
    name: "contact-us___de"
  }, {
    path: "/de/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___de"
  }, {
    path: "/de/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___de"
  }, {
    path: "/de/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___de"
  }, {
    path: "/de/enter-amount",
    component: _6777a957,
    name: "enter-amount___de"
  }, {
    path: "/de/failed-bid",
    component: _d97eb752,
    name: "failed-bid___de"
  }, {
    path: "/de/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___de"
  }, {
    path: "/de/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___de"
  }, {
    path: "/de/faq",
    component: _ca324bc8,
    name: "faq___de",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___de"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___de"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___de"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___de"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___de"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___de"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___de"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___de"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___de"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___de"
    }]
  }, {
    path: "/de/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___de"
  }, {
    path: "/de/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___de"
  }, {
    path: "/de/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___de"
  }, {
    path: "/de/imprint",
    component: _3466728f,
    name: "imprint___de"
  }, {
    path: "/de/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___de"
  }, {
    path: "/de/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___de"
  }, {
    path: "/de/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___de"
  }, {
    path: "/de/login",
    component: _10ba8d22,
    name: "login___de"
  }, {
    path: "/de/login-pending",
    component: _6823ea39,
    name: "login-pending___de"
  }, {
    path: "/de/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___de"
  }, {
    path: "/de/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___de"
  }, {
    path: "/de/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___de"
  }, {
    path: "/de/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___de"
  }, {
    path: "/de/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___de"
  }, {
    path: "/de/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___de"
  }, {
    path: "/de/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___de"
  }, {
    path: "/de/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___de"
  }, {
    path: "/de/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___de"
  }, {
    path: "/de/lp-collections",
    component: _35cb2336,
    name: "lp-collections___de"
  }, {
    path: "/de/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___de"
  }, {
    path: "/de/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___de"
  }, {
    path: "/de/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___de"
  }, {
    path: "/de/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___de"
  }, {
    path: "/de/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___de"
  }, {
    path: "/de/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___de"
  }, {
    path: "/de/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___de"
  }, {
    path: "/de/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___de"
  }, {
    path: "/de/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___de"
  }, {
    path: "/de/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___de"
  }, {
    path: "/de/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___de"
  }, {
    path: "/de/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___de"
  }, {
    path: "/de/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___de"
  }, {
    path: "/de/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___de"
  }, {
    path: "/de/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___de"
  }, {
    path: "/de/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___de"
  }, {
    path: "/de/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___de"
  }, {
    path: "/de/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___de"
  }, {
    path: "/de/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___de"
  }, {
    path: "/de/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___de"
  }, {
    path: "/de/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___de"
  }, {
    path: "/de/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___de"
  }, {
    path: "/de/lp-investor",
    component: _4894a247,
    name: "lp-investor___de"
  }, {
    path: "/de/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___de"
  }, {
    path: "/de/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___de"
  }, {
    path: "/de/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___de"
  }, {
    path: "/de/lp-lover",
    component: _74551bbe,
    name: "lp-lover___de"
  }, {
    path: "/de/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___de"
  }, {
    path: "/de/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___de"
  }, {
    path: "/de/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___de"
  }, {
    path: "/de/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___de"
  }, {
    path: "/de/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___de"
  }, {
    path: "/de/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___de"
  }, {
    path: "/de/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___de"
  }, {
    path: "/de/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___de"
  }, {
    path: "/de/my-account",
    component: _09d48279,
    name: "my-account___de"
  }, {
    path: "/de/news",
    component: _3b011b86,
    name: "news___de"
  }, {
    path: "/de/notifications",
    component: _5172b96e,
    name: "notifications___de"
  }, {
    path: "/de/payment-mango",
    component: _58649f7e,
    name: "payment-mango___de"
  }, {
    path: "/de/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___de"
  }, {
    path: "/de/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___de"
  }, {
    path: "/de/paypal-success",
    component: _4fc925da,
    name: "paypal-success___de"
  }, {
    path: "/de/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___de"
  }, {
    path: "/de/register",
    component: _5fba350d,
    name: "register___de"
  }, {
    path: "/de/registration-active",
    component: _1f17bfa0,
    name: "registration-active___de"
  }, {
    path: "/de/registration-device",
    component: _58599220,
    name: "registration-device___de"
  }, {
    path: "/de/registration-expired",
    component: _00b2430a,
    name: "registration-expired___de"
  }, {
    path: "/de/registration-pending",
    component: _478637ed,
    name: "registration-pending___de"
  }, {
    path: "/de/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___de"
  }, {
    path: "/de/reset-password",
    component: _bb1ec17a,
    name: "reset-password___de"
  }, {
    path: "/de/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___de"
  }, {
    path: "/de/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___de"
  }, {
    path: "/de/start-investing",
    component: _148c7fb0,
    name: "start-investing___de"
  }, {
    path: "/de/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___de"
  }, {
    path: "/de/stripe-return",
    component: _b3047194,
    name: "stripe-return___de"
  }, {
    path: "/de/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___de"
  }, {
    path: "/de/success-bid",
    component: _2075a2b9,
    name: "success-bid___de"
  }, {
    path: "/de/success-buy",
    component: _3617b7c2,
    name: "success-buy___de"
  }, {
    path: "/de/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___de"
  }, {
    path: "/de/success-collection",
    component: _c32f6fdc,
    name: "success-collection___de"
  }, {
    path: "/de/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___de"
  }, {
    path: "/de/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___de"
  }, {
    path: "/de/success-gift",
    component: _067002b8,
    name: "success-gift___de"
  }, {
    path: "/de/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___de"
  }, {
    path: "/de/success-invoice",
    component: _ad43422e,
    name: "success-invoice___de"
  }, {
    path: "/de/support",
    component: _1c176355,
    name: "support___de"
  }, {
    path: "/de/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___de"
  }, {
    path: "/de/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___de"
  }, {
    path: "/de/trading-desk",
    component: _f59a091c,
    name: "trading-desk___de"
  }, {
    path: "/de/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___de"
  }, {
    path: "/de/valentines-day",
    component: _91c1d014,
    name: "valentines-day___de"
  }, {
    path: "/de/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___de"
  }, {
    path: "/de/wine-academy",
    component: _dca247dc,
    name: "wine-academy___de"
  }, {
    path: "/de/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___de"
  }, {
    path: "/de/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___de"
  }, {
    path: "/de/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___de"
  }, {
    path: "/de/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___de"
  }, {
    path: "/de/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___de"
  }, {
    path: "/de/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___de"
  }, {
    path: "/de/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___de"
  }, {
    path: "/de/wine-guide",
    component: _509300a5,
    name: "wine-guide___de"
  }, {
    path: "/de/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___de"
  }, {
    path: "/de/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___de"
  }, {
    path: "/de/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___de"
  }, {
    path: "/de/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___de"
  }, {
    path: "/de/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___de"
  }, {
    path: "/de/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___de"
  }, {
    path: "/es/about",
    component: _0e433b93,
    name: "about___es"
  }, {
    path: "/es/about-us",
    component: _e9b98470,
    name: "about-us___es"
  }, {
    path: "/es/activate-gift",
    component: _698c8d60,
    name: "activate-gift___es"
  }, {
    path: "/es/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___es"
  }, {
    path: "/es/app",
    component: _a81b68b2,
    name: "app___es"
  }, {
    path: "/es/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___es"
  }, {
    path: "/es/appscreens",
    component: _0030ccb0,
    name: "appscreens___es"
  }, {
    path: "/es/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___es"
  }, {
    path: "/es/barcode",
    component: _26e5e8f4,
    name: "barcode___es"
  }, {
    path: "/es/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___es"
  }, {
    path: "/es/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___es"
  }, {
    path: "/es/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___es"
  }, {
    path: "/es/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___es"
  }, {
    path: "/es/collections",
    component: _4e64555e,
    name: "collections___es"
  }, {
    path: "/es/collections-cart",
    component: _78c65e42,
    name: "collections-cart___es"
  }, {
    path: "/es/contact-us",
    component: _521bf555,
    name: "contact-us___es"
  }, {
    path: "/es/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___es"
  }, {
    path: "/es/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___es"
  }, {
    path: "/es/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___es"
  }, {
    path: "/es/enter-amount",
    component: _6777a957,
    name: "enter-amount___es"
  }, {
    path: "/es/failed-bid",
    component: _d97eb752,
    name: "failed-bid___es"
  }, {
    path: "/es/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___es"
  }, {
    path: "/es/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___es"
  }, {
    path: "/es/faq",
    component: _ca324bc8,
    name: "faq___es",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___es"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___es"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___es"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___es"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___es"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___es"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___es"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___es"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___es"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___es"
    }]
  }, {
    path: "/es/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___es"
  }, {
    path: "/es/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___es"
  }, {
    path: "/es/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___es"
  }, {
    path: "/es/imprint",
    component: _3466728f,
    name: "imprint___es"
  }, {
    path: "/es/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___es"
  }, {
    path: "/es/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___es"
  }, {
    path: "/es/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___es"
  }, {
    path: "/es/login",
    component: _10ba8d22,
    name: "login___es"
  }, {
    path: "/es/login-pending",
    component: _6823ea39,
    name: "login-pending___es"
  }, {
    path: "/es/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___es"
  }, {
    path: "/es/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___es"
  }, {
    path: "/es/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___es"
  }, {
    path: "/es/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___es"
  }, {
    path: "/es/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___es"
  }, {
    path: "/es/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___es"
  }, {
    path: "/es/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___es"
  }, {
    path: "/es/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___es"
  }, {
    path: "/es/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___es"
  }, {
    path: "/es/lp-collections",
    component: _35cb2336,
    name: "lp-collections___es"
  }, {
    path: "/es/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___es"
  }, {
    path: "/es/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___es"
  }, {
    path: "/es/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___es"
  }, {
    path: "/es/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___es"
  }, {
    path: "/es/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___es"
  }, {
    path: "/es/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___es"
  }, {
    path: "/es/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___es"
  }, {
    path: "/es/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___es"
  }, {
    path: "/es/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___es"
  }, {
    path: "/es/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___es"
  }, {
    path: "/es/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___es"
  }, {
    path: "/es/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___es"
  }, {
    path: "/es/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___es"
  }, {
    path: "/es/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___es"
  }, {
    path: "/es/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___es"
  }, {
    path: "/es/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___es"
  }, {
    path: "/es/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___es"
  }, {
    path: "/es/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___es"
  }, {
    path: "/es/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___es"
  }, {
    path: "/es/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___es"
  }, {
    path: "/es/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___es"
  }, {
    path: "/es/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___es"
  }, {
    path: "/es/lp-investor",
    component: _4894a247,
    name: "lp-investor___es"
  }, {
    path: "/es/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___es"
  }, {
    path: "/es/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___es"
  }, {
    path: "/es/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___es"
  }, {
    path: "/es/lp-lover",
    component: _74551bbe,
    name: "lp-lover___es"
  }, {
    path: "/es/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___es"
  }, {
    path: "/es/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___es"
  }, {
    path: "/es/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___es"
  }, {
    path: "/es/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___es"
  }, {
    path: "/es/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___es"
  }, {
    path: "/es/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___es"
  }, {
    path: "/es/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___es"
  }, {
    path: "/es/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___es"
  }, {
    path: "/es/my-account",
    component: _09d48279,
    name: "my-account___es"
  }, {
    path: "/es/news",
    component: _3b011b86,
    name: "news___es"
  }, {
    path: "/es/notifications",
    component: _5172b96e,
    name: "notifications___es"
  }, {
    path: "/es/payment-mango",
    component: _58649f7e,
    name: "payment-mango___es"
  }, {
    path: "/es/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___es"
  }, {
    path: "/es/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___es"
  }, {
    path: "/es/paypal-success",
    component: _4fc925da,
    name: "paypal-success___es"
  }, {
    path: "/es/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___es"
  }, {
    path: "/es/register",
    component: _5fba350d,
    name: "register___es"
  }, {
    path: "/es/registration-active",
    component: _1f17bfa0,
    name: "registration-active___es"
  }, {
    path: "/es/registration-device",
    component: _58599220,
    name: "registration-device___es"
  }, {
    path: "/es/registration-expired",
    component: _00b2430a,
    name: "registration-expired___es"
  }, {
    path: "/es/registration-pending",
    component: _478637ed,
    name: "registration-pending___es"
  }, {
    path: "/es/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___es"
  }, {
    path: "/es/reset-password",
    component: _bb1ec17a,
    name: "reset-password___es"
  }, {
    path: "/es/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___es"
  }, {
    path: "/es/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___es"
  }, {
    path: "/es/start-investing",
    component: _148c7fb0,
    name: "start-investing___es"
  }, {
    path: "/es/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___es"
  }, {
    path: "/es/stripe-return",
    component: _b3047194,
    name: "stripe-return___es"
  }, {
    path: "/es/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___es"
  }, {
    path: "/es/success-bid",
    component: _2075a2b9,
    name: "success-bid___es"
  }, {
    path: "/es/success-buy",
    component: _3617b7c2,
    name: "success-buy___es"
  }, {
    path: "/es/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___es"
  }, {
    path: "/es/success-collection",
    component: _c32f6fdc,
    name: "success-collection___es"
  }, {
    path: "/es/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___es"
  }, {
    path: "/es/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___es"
  }, {
    path: "/es/success-gift",
    component: _067002b8,
    name: "success-gift___es"
  }, {
    path: "/es/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___es"
  }, {
    path: "/es/success-invoice",
    component: _ad43422e,
    name: "success-invoice___es"
  }, {
    path: "/es/support",
    component: _1c176355,
    name: "support___es"
  }, {
    path: "/es/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___es"
  }, {
    path: "/es/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___es"
  }, {
    path: "/es/trading-desk",
    component: _f59a091c,
    name: "trading-desk___es"
  }, {
    path: "/es/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___es"
  }, {
    path: "/es/valentines-day",
    component: _91c1d014,
    name: "valentines-day___es"
  }, {
    path: "/es/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___es"
  }, {
    path: "/es/wine-academy",
    component: _dca247dc,
    name: "wine-academy___es"
  }, {
    path: "/es/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___es"
  }, {
    path: "/es/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___es"
  }, {
    path: "/es/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___es"
  }, {
    path: "/es/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___es"
  }, {
    path: "/es/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___es"
  }, {
    path: "/es/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___es"
  }, {
    path: "/es/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___es"
  }, {
    path: "/es/wine-guide",
    component: _509300a5,
    name: "wine-guide___es"
  }, {
    path: "/es/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___es"
  }, {
    path: "/es/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___es"
  }, {
    path: "/es/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___es"
  }, {
    path: "/es/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___es"
  }, {
    path: "/es/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___es"
  }, {
    path: "/es/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___es"
  }, {
    path: "/fr/about",
    component: _0e433b93,
    name: "about___fr"
  }, {
    path: "/fr/about-us",
    component: _e9b98470,
    name: "about-us___fr"
  }, {
    path: "/fr/activate-gift",
    component: _698c8d60,
    name: "activate-gift___fr"
  }, {
    path: "/fr/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___fr"
  }, {
    path: "/fr/app",
    component: _a81b68b2,
    name: "app___fr"
  }, {
    path: "/fr/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___fr"
  }, {
    path: "/fr/appscreens",
    component: _0030ccb0,
    name: "appscreens___fr"
  }, {
    path: "/fr/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___fr"
  }, {
    path: "/fr/barcode",
    component: _26e5e8f4,
    name: "barcode___fr"
  }, {
    path: "/fr/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___fr"
  }, {
    path: "/fr/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___fr"
  }, {
    path: "/fr/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___fr"
  }, {
    path: "/fr/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___fr"
  }, {
    path: "/fr/collections",
    component: _4e64555e,
    name: "collections___fr"
  }, {
    path: "/fr/collections-cart",
    component: _78c65e42,
    name: "collections-cart___fr"
  }, {
    path: "/fr/contact-us",
    component: _521bf555,
    name: "contact-us___fr"
  }, {
    path: "/fr/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___fr"
  }, {
    path: "/fr/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___fr"
  }, {
    path: "/fr/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___fr"
  }, {
    path: "/fr/enter-amount",
    component: _6777a957,
    name: "enter-amount___fr"
  }, {
    path: "/fr/failed-bid",
    component: _d97eb752,
    name: "failed-bid___fr"
  }, {
    path: "/fr/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___fr"
  }, {
    path: "/fr/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___fr"
  }, {
    path: "/fr/faq",
    component: _ca324bc8,
    name: "faq___fr",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___fr"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___fr"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___fr"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___fr"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___fr"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___fr"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___fr"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___fr"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___fr"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___fr"
    }]
  }, {
    path: "/fr/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___fr"
  }, {
    path: "/fr/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___fr"
  }, {
    path: "/fr/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___fr"
  }, {
    path: "/fr/imprint",
    component: _3466728f,
    name: "imprint___fr"
  }, {
    path: "/fr/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___fr"
  }, {
    path: "/fr/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___fr"
  }, {
    path: "/fr/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___fr"
  }, {
    path: "/fr/login",
    component: _10ba8d22,
    name: "login___fr"
  }, {
    path: "/fr/login-pending",
    component: _6823ea39,
    name: "login-pending___fr"
  }, {
    path: "/fr/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___fr"
  }, {
    path: "/fr/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___fr"
  }, {
    path: "/fr/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___fr"
  }, {
    path: "/fr/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___fr"
  }, {
    path: "/fr/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___fr"
  }, {
    path: "/fr/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___fr"
  }, {
    path: "/fr/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___fr"
  }, {
    path: "/fr/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___fr"
  }, {
    path: "/fr/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___fr"
  }, {
    path: "/fr/lp-collections",
    component: _35cb2336,
    name: "lp-collections___fr"
  }, {
    path: "/fr/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___fr"
  }, {
    path: "/fr/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___fr"
  }, {
    path: "/fr/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___fr"
  }, {
    path: "/fr/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___fr"
  }, {
    path: "/fr/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___fr"
  }, {
    path: "/fr/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___fr"
  }, {
    path: "/fr/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___fr"
  }, {
    path: "/fr/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___fr"
  }, {
    path: "/fr/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___fr"
  }, {
    path: "/fr/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___fr"
  }, {
    path: "/fr/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___fr"
  }, {
    path: "/fr/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___fr"
  }, {
    path: "/fr/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___fr"
  }, {
    path: "/fr/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___fr"
  }, {
    path: "/fr/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___fr"
  }, {
    path: "/fr/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___fr"
  }, {
    path: "/fr/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___fr"
  }, {
    path: "/fr/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___fr"
  }, {
    path: "/fr/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___fr"
  }, {
    path: "/fr/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___fr"
  }, {
    path: "/fr/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___fr"
  }, {
    path: "/fr/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___fr"
  }, {
    path: "/fr/lp-investor",
    component: _4894a247,
    name: "lp-investor___fr"
  }, {
    path: "/fr/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___fr"
  }, {
    path: "/fr/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___fr"
  }, {
    path: "/fr/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___fr"
  }, {
    path: "/fr/lp-lover",
    component: _74551bbe,
    name: "lp-lover___fr"
  }, {
    path: "/fr/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___fr"
  }, {
    path: "/fr/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___fr"
  }, {
    path: "/fr/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___fr"
  }, {
    path: "/fr/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___fr"
  }, {
    path: "/fr/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___fr"
  }, {
    path: "/fr/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___fr"
  }, {
    path: "/fr/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___fr"
  }, {
    path: "/fr/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___fr"
  }, {
    path: "/fr/my-account",
    component: _09d48279,
    name: "my-account___fr"
  }, {
    path: "/fr/news",
    component: _3b011b86,
    name: "news___fr"
  }, {
    path: "/fr/notifications",
    component: _5172b96e,
    name: "notifications___fr"
  }, {
    path: "/fr/payment-mango",
    component: _58649f7e,
    name: "payment-mango___fr"
  }, {
    path: "/fr/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___fr"
  }, {
    path: "/fr/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___fr"
  }, {
    path: "/fr/paypal-success",
    component: _4fc925da,
    name: "paypal-success___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/register",
    component: _5fba350d,
    name: "register___fr"
  }, {
    path: "/fr/registration-active",
    component: _1f17bfa0,
    name: "registration-active___fr"
  }, {
    path: "/fr/registration-device",
    component: _58599220,
    name: "registration-device___fr"
  }, {
    path: "/fr/registration-expired",
    component: _00b2430a,
    name: "registration-expired___fr"
  }, {
    path: "/fr/registration-pending",
    component: _478637ed,
    name: "registration-pending___fr"
  }, {
    path: "/fr/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___fr"
  }, {
    path: "/fr/reset-password",
    component: _bb1ec17a,
    name: "reset-password___fr"
  }, {
    path: "/fr/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___fr"
  }, {
    path: "/fr/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___fr"
  }, {
    path: "/fr/start-investing",
    component: _148c7fb0,
    name: "start-investing___fr"
  }, {
    path: "/fr/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___fr"
  }, {
    path: "/fr/stripe-return",
    component: _b3047194,
    name: "stripe-return___fr"
  }, {
    path: "/fr/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___fr"
  }, {
    path: "/fr/success-bid",
    component: _2075a2b9,
    name: "success-bid___fr"
  }, {
    path: "/fr/success-buy",
    component: _3617b7c2,
    name: "success-buy___fr"
  }, {
    path: "/fr/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___fr"
  }, {
    path: "/fr/success-collection",
    component: _c32f6fdc,
    name: "success-collection___fr"
  }, {
    path: "/fr/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___fr"
  }, {
    path: "/fr/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___fr"
  }, {
    path: "/fr/success-gift",
    component: _067002b8,
    name: "success-gift___fr"
  }, {
    path: "/fr/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___fr"
  }, {
    path: "/fr/success-invoice",
    component: _ad43422e,
    name: "success-invoice___fr"
  }, {
    path: "/fr/support",
    component: _1c176355,
    name: "support___fr"
  }, {
    path: "/fr/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___fr"
  }, {
    path: "/fr/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___fr"
  }, {
    path: "/fr/trading-desk",
    component: _f59a091c,
    name: "trading-desk___fr"
  }, {
    path: "/fr/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___fr"
  }, {
    path: "/fr/valentines-day",
    component: _91c1d014,
    name: "valentines-day___fr"
  }, {
    path: "/fr/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___fr"
  }, {
    path: "/fr/wine-academy",
    component: _dca247dc,
    name: "wine-academy___fr"
  }, {
    path: "/fr/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___fr"
  }, {
    path: "/fr/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___fr"
  }, {
    path: "/fr/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___fr"
  }, {
    path: "/fr/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___fr"
  }, {
    path: "/fr/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___fr"
  }, {
    path: "/fr/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___fr"
  }, {
    path: "/fr/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___fr"
  }, {
    path: "/fr/wine-guide",
    component: _509300a5,
    name: "wine-guide___fr"
  }, {
    path: "/fr/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___fr"
  }, {
    path: "/fr/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___fr"
  }, {
    path: "/fr/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___fr"
  }, {
    path: "/fr/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___fr"
  }, {
    path: "/fr/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___fr"
  }, {
    path: "/fr/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___fr"
  }, {
    path: "/home/collections",
    component: _308d03cf,
    name: "home-collections___en"
  }, {
    path: "/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___en"
  }, {
    path: "/home/live",
    component: _7bb0d1a2,
    name: "home-live___en"
  }, {
    path: "/it/about",
    component: _0e433b93,
    name: "about___it"
  }, {
    path: "/it/about-us",
    component: _e9b98470,
    name: "about-us___it"
  }, {
    path: "/it/activate-gift",
    component: _698c8d60,
    name: "activate-gift___it"
  }, {
    path: "/it/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___it"
  }, {
    path: "/it/app",
    component: _a81b68b2,
    name: "app___it"
  }, {
    path: "/it/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___it"
  }, {
    path: "/it/appscreens",
    component: _0030ccb0,
    name: "appscreens___it"
  }, {
    path: "/it/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___it"
  }, {
    path: "/it/barcode",
    component: _26e5e8f4,
    name: "barcode___it"
  }, {
    path: "/it/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___it"
  }, {
    path: "/it/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___it"
  }, {
    path: "/it/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___it"
  }, {
    path: "/it/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___it"
  }, {
    path: "/it/collections",
    component: _4e64555e,
    name: "collections___it"
  }, {
    path: "/it/collections-cart",
    component: _78c65e42,
    name: "collections-cart___it"
  }, {
    path: "/it/contact-us",
    component: _521bf555,
    name: "contact-us___it"
  }, {
    path: "/it/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___it"
  }, {
    path: "/it/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___it"
  }, {
    path: "/it/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___it"
  }, {
    path: "/it/enter-amount",
    component: _6777a957,
    name: "enter-amount___it"
  }, {
    path: "/it/failed-bid",
    component: _d97eb752,
    name: "failed-bid___it"
  }, {
    path: "/it/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___it"
  }, {
    path: "/it/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___it"
  }, {
    path: "/it/faq",
    component: _ca324bc8,
    name: "faq___it",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___it"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___it"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___it"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___it"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___it"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___it"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___it"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___it"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___it"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___it"
    }]
  }, {
    path: "/it/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___it"
  }, {
    path: "/it/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___it"
  }, {
    path: "/it/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___it"
  }, {
    path: "/it/imprint",
    component: _3466728f,
    name: "imprint___it"
  }, {
    path: "/it/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___it"
  }, {
    path: "/it/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___it"
  }, {
    path: "/it/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___it"
  }, {
    path: "/it/login",
    component: _10ba8d22,
    name: "login___it"
  }, {
    path: "/it/login-pending",
    component: _6823ea39,
    name: "login-pending___it"
  }, {
    path: "/it/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___it"
  }, {
    path: "/it/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___it"
  }, {
    path: "/it/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___it"
  }, {
    path: "/it/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___it"
  }, {
    path: "/it/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___it"
  }, {
    path: "/it/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___it"
  }, {
    path: "/it/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___it"
  }, {
    path: "/it/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___it"
  }, {
    path: "/it/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___it"
  }, {
    path: "/it/lp-collections",
    component: _35cb2336,
    name: "lp-collections___it"
  }, {
    path: "/it/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___it"
  }, {
    path: "/it/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___it"
  }, {
    path: "/it/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___it"
  }, {
    path: "/it/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___it"
  }, {
    path: "/it/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___it"
  }, {
    path: "/it/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___it"
  }, {
    path: "/it/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___it"
  }, {
    path: "/it/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___it"
  }, {
    path: "/it/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___it"
  }, {
    path: "/it/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___it"
  }, {
    path: "/it/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___it"
  }, {
    path: "/it/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___it"
  }, {
    path: "/it/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___it"
  }, {
    path: "/it/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___it"
  }, {
    path: "/it/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___it"
  }, {
    path: "/it/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___it"
  }, {
    path: "/it/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___it"
  }, {
    path: "/it/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___it"
  }, {
    path: "/it/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___it"
  }, {
    path: "/it/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___it"
  }, {
    path: "/it/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___it"
  }, {
    path: "/it/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___it"
  }, {
    path: "/it/lp-investor",
    component: _4894a247,
    name: "lp-investor___it"
  }, {
    path: "/it/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___it"
  }, {
    path: "/it/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___it"
  }, {
    path: "/it/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___it"
  }, {
    path: "/it/lp-lover",
    component: _74551bbe,
    name: "lp-lover___it"
  }, {
    path: "/it/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___it"
  }, {
    path: "/it/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___it"
  }, {
    path: "/it/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___it"
  }, {
    path: "/it/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___it"
  }, {
    path: "/it/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___it"
  }, {
    path: "/it/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___it"
  }, {
    path: "/it/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___it"
  }, {
    path: "/it/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___it"
  }, {
    path: "/it/my-account",
    component: _09d48279,
    name: "my-account___it"
  }, {
    path: "/it/news",
    component: _3b011b86,
    name: "news___it"
  }, {
    path: "/it/notifications",
    component: _5172b96e,
    name: "notifications___it"
  }, {
    path: "/it/payment-mango",
    component: _58649f7e,
    name: "payment-mango___it"
  }, {
    path: "/it/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___it"
  }, {
    path: "/it/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___it"
  }, {
    path: "/it/paypal-success",
    component: _4fc925da,
    name: "paypal-success___it"
  }, {
    path: "/it/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___it"
  }, {
    path: "/it/register",
    component: _5fba350d,
    name: "register___it"
  }, {
    path: "/it/registration-active",
    component: _1f17bfa0,
    name: "registration-active___it"
  }, {
    path: "/it/registration-device",
    component: _58599220,
    name: "registration-device___it"
  }, {
    path: "/it/registration-expired",
    component: _00b2430a,
    name: "registration-expired___it"
  }, {
    path: "/it/registration-pending",
    component: _478637ed,
    name: "registration-pending___it"
  }, {
    path: "/it/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___it"
  }, {
    path: "/it/reset-password",
    component: _bb1ec17a,
    name: "reset-password___it"
  }, {
    path: "/it/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___it"
  }, {
    path: "/it/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___it"
  }, {
    path: "/it/start-investing",
    component: _148c7fb0,
    name: "start-investing___it"
  }, {
    path: "/it/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___it"
  }, {
    path: "/it/stripe-return",
    component: _b3047194,
    name: "stripe-return___it"
  }, {
    path: "/it/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___it"
  }, {
    path: "/it/success-bid",
    component: _2075a2b9,
    name: "success-bid___it"
  }, {
    path: "/it/success-buy",
    component: _3617b7c2,
    name: "success-buy___it"
  }, {
    path: "/it/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___it"
  }, {
    path: "/it/success-collection",
    component: _c32f6fdc,
    name: "success-collection___it"
  }, {
    path: "/it/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___it"
  }, {
    path: "/it/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___it"
  }, {
    path: "/it/success-gift",
    component: _067002b8,
    name: "success-gift___it"
  }, {
    path: "/it/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___it"
  }, {
    path: "/it/success-invoice",
    component: _ad43422e,
    name: "success-invoice___it"
  }, {
    path: "/it/support",
    component: _1c176355,
    name: "support___it"
  }, {
    path: "/it/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___it"
  }, {
    path: "/it/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___it"
  }, {
    path: "/it/trading-desk",
    component: _f59a091c,
    name: "trading-desk___it"
  }, {
    path: "/it/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___it"
  }, {
    path: "/it/valentines-day",
    component: _91c1d014,
    name: "valentines-day___it"
  }, {
    path: "/it/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___it"
  }, {
    path: "/it/wine-academy",
    component: _dca247dc,
    name: "wine-academy___it"
  }, {
    path: "/it/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___it"
  }, {
    path: "/it/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___it"
  }, {
    path: "/it/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___it"
  }, {
    path: "/it/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___it"
  }, {
    path: "/it/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___it"
  }, {
    path: "/it/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___it"
  }, {
    path: "/it/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___it"
  }, {
    path: "/it/wine-guide",
    component: _509300a5,
    name: "wine-guide___it"
  }, {
    path: "/it/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___it"
  }, {
    path: "/it/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___it"
  }, {
    path: "/it/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___it"
  }, {
    path: "/it/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___it"
  }, {
    path: "/it/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___it"
  }, {
    path: "/it/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___it"
  }, {
    path: "/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___en"
  }, {
    path: "/ru/about",
    component: _0e433b93,
    name: "about___ru"
  }, {
    path: "/ru/about-us",
    component: _e9b98470,
    name: "about-us___ru"
  }, {
    path: "/ru/activate-gift",
    component: _698c8d60,
    name: "activate-gift___ru"
  }, {
    path: "/ru/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___ru"
  }, {
    path: "/ru/app",
    component: _a81b68b2,
    name: "app___ru"
  }, {
    path: "/ru/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___ru"
  }, {
    path: "/ru/appscreens",
    component: _0030ccb0,
    name: "appscreens___ru"
  }, {
    path: "/ru/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___ru"
  }, {
    path: "/ru/barcode",
    component: _26e5e8f4,
    name: "barcode___ru"
  }, {
    path: "/ru/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___ru"
  }, {
    path: "/ru/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___ru"
  }, {
    path: "/ru/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___ru"
  }, {
    path: "/ru/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___ru"
  }, {
    path: "/ru/collections",
    component: _4e64555e,
    name: "collections___ru"
  }, {
    path: "/ru/collections-cart",
    component: _78c65e42,
    name: "collections-cart___ru"
  }, {
    path: "/ru/contact-us",
    component: _521bf555,
    name: "contact-us___ru"
  }, {
    path: "/ru/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___ru"
  }, {
    path: "/ru/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___ru"
  }, {
    path: "/ru/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___ru"
  }, {
    path: "/ru/enter-amount",
    component: _6777a957,
    name: "enter-amount___ru"
  }, {
    path: "/ru/failed-bid",
    component: _d97eb752,
    name: "failed-bid___ru"
  }, {
    path: "/ru/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___ru"
  }, {
    path: "/ru/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___ru"
  }, {
    path: "/ru/faq",
    component: _ca324bc8,
    name: "faq___ru",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___ru"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___ru"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___ru"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___ru"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___ru"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___ru"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___ru"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___ru"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___ru"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___ru"
    }]
  }, {
    path: "/ru/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___ru"
  }, {
    path: "/ru/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___ru"
  }, {
    path: "/ru/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___ru"
  }, {
    path: "/ru/imprint",
    component: _3466728f,
    name: "imprint___ru"
  }, {
    path: "/ru/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___ru"
  }, {
    path: "/ru/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___ru"
  }, {
    path: "/ru/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___ru"
  }, {
    path: "/ru/login",
    component: _10ba8d22,
    name: "login___ru"
  }, {
    path: "/ru/login-pending",
    component: _6823ea39,
    name: "login-pending___ru"
  }, {
    path: "/ru/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___ru"
  }, {
    path: "/ru/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___ru"
  }, {
    path: "/ru/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___ru"
  }, {
    path: "/ru/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___ru"
  }, {
    path: "/ru/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___ru"
  }, {
    path: "/ru/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___ru"
  }, {
    path: "/ru/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___ru"
  }, {
    path: "/ru/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___ru"
  }, {
    path: "/ru/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___ru"
  }, {
    path: "/ru/lp-collections",
    component: _35cb2336,
    name: "lp-collections___ru"
  }, {
    path: "/ru/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___ru"
  }, {
    path: "/ru/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___ru"
  }, {
    path: "/ru/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___ru"
  }, {
    path: "/ru/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___ru"
  }, {
    path: "/ru/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___ru"
  }, {
    path: "/ru/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___ru"
  }, {
    path: "/ru/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___ru"
  }, {
    path: "/ru/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___ru"
  }, {
    path: "/ru/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___ru"
  }, {
    path: "/ru/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___ru"
  }, {
    path: "/ru/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___ru"
  }, {
    path: "/ru/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___ru"
  }, {
    path: "/ru/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___ru"
  }, {
    path: "/ru/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___ru"
  }, {
    path: "/ru/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___ru"
  }, {
    path: "/ru/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___ru"
  }, {
    path: "/ru/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___ru"
  }, {
    path: "/ru/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___ru"
  }, {
    path: "/ru/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___ru"
  }, {
    path: "/ru/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___ru"
  }, {
    path: "/ru/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___ru"
  }, {
    path: "/ru/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___ru"
  }, {
    path: "/ru/lp-investor",
    component: _4894a247,
    name: "lp-investor___ru"
  }, {
    path: "/ru/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___ru"
  }, {
    path: "/ru/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___ru"
  }, {
    path: "/ru/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___ru"
  }, {
    path: "/ru/lp-lover",
    component: _74551bbe,
    name: "lp-lover___ru"
  }, {
    path: "/ru/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___ru"
  }, {
    path: "/ru/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___ru"
  }, {
    path: "/ru/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___ru"
  }, {
    path: "/ru/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___ru"
  }, {
    path: "/ru/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___ru"
  }, {
    path: "/ru/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___ru"
  }, {
    path: "/ru/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___ru"
  }, {
    path: "/ru/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___ru"
  }, {
    path: "/ru/my-account",
    component: _09d48279,
    name: "my-account___ru"
  }, {
    path: "/ru/news",
    component: _3b011b86,
    name: "news___ru"
  }, {
    path: "/ru/notifications",
    component: _5172b96e,
    name: "notifications___ru"
  }, {
    path: "/ru/payment-mango",
    component: _58649f7e,
    name: "payment-mango___ru"
  }, {
    path: "/ru/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___ru"
  }, {
    path: "/ru/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___ru"
  }, {
    path: "/ru/paypal-success",
    component: _4fc925da,
    name: "paypal-success___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___ru"
  }, {
    path: "/ru/register",
    component: _5fba350d,
    name: "register___ru"
  }, {
    path: "/ru/registration-active",
    component: _1f17bfa0,
    name: "registration-active___ru"
  }, {
    path: "/ru/registration-device",
    component: _58599220,
    name: "registration-device___ru"
  }, {
    path: "/ru/registration-expired",
    component: _00b2430a,
    name: "registration-expired___ru"
  }, {
    path: "/ru/registration-pending",
    component: _478637ed,
    name: "registration-pending___ru"
  }, {
    path: "/ru/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___ru"
  }, {
    path: "/ru/reset-password",
    component: _bb1ec17a,
    name: "reset-password___ru"
  }, {
    path: "/ru/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___ru"
  }, {
    path: "/ru/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___ru"
  }, {
    path: "/ru/start-investing",
    component: _148c7fb0,
    name: "start-investing___ru"
  }, {
    path: "/ru/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___ru"
  }, {
    path: "/ru/stripe-return",
    component: _b3047194,
    name: "stripe-return___ru"
  }, {
    path: "/ru/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___ru"
  }, {
    path: "/ru/success-bid",
    component: _2075a2b9,
    name: "success-bid___ru"
  }, {
    path: "/ru/success-buy",
    component: _3617b7c2,
    name: "success-buy___ru"
  }, {
    path: "/ru/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___ru"
  }, {
    path: "/ru/success-collection",
    component: _c32f6fdc,
    name: "success-collection___ru"
  }, {
    path: "/ru/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___ru"
  }, {
    path: "/ru/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___ru"
  }, {
    path: "/ru/success-gift",
    component: _067002b8,
    name: "success-gift___ru"
  }, {
    path: "/ru/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___ru"
  }, {
    path: "/ru/success-invoice",
    component: _ad43422e,
    name: "success-invoice___ru"
  }, {
    path: "/ru/support",
    component: _1c176355,
    name: "support___ru"
  }, {
    path: "/ru/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___ru"
  }, {
    path: "/ru/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___ru"
  }, {
    path: "/ru/trading-desk",
    component: _f59a091c,
    name: "trading-desk___ru"
  }, {
    path: "/ru/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___ru"
  }, {
    path: "/ru/valentines-day",
    component: _91c1d014,
    name: "valentines-day___ru"
  }, {
    path: "/ru/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___ru"
  }, {
    path: "/ru/wine-academy",
    component: _dca247dc,
    name: "wine-academy___ru"
  }, {
    path: "/ru/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___ru"
  }, {
    path: "/ru/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___ru"
  }, {
    path: "/ru/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___ru"
  }, {
    path: "/ru/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___ru"
  }, {
    path: "/ru/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___ru"
  }, {
    path: "/ru/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___ru"
  }, {
    path: "/ru/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___ru"
  }, {
    path: "/ru/wine-guide",
    component: _509300a5,
    name: "wine-guide___ru"
  }, {
    path: "/ru/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___ru"
  }, {
    path: "/ru/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___ru"
  }, {
    path: "/ru/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___ru"
  }, {
    path: "/ru/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___ru"
  }, {
    path: "/ru/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___ru"
  }, {
    path: "/ru/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___ru"
  }, {
    path: "/sv/about",
    component: _0e433b93,
    name: "about___sv"
  }, {
    path: "/sv/about-us",
    component: _e9b98470,
    name: "about-us___sv"
  }, {
    path: "/sv/activate-gift",
    component: _698c8d60,
    name: "activate-gift___sv"
  }, {
    path: "/sv/add-bank-account",
    component: _7e60ab32,
    name: "add-bank-account___sv"
  }, {
    path: "/sv/app",
    component: _a81b68b2,
    name: "app___sv"
  }, {
    path: "/sv/apple-auth",
    component: _cedc1bb6,
    name: "apple-auth___sv"
  }, {
    path: "/sv/appscreens",
    component: _0030ccb0,
    name: "appscreens___sv"
  }, {
    path: "/sv/bank-accounts",
    component: _3a81515d,
    name: "bank-accounts___sv"
  }, {
    path: "/sv/barcode",
    component: _26e5e8f4,
    name: "barcode___sv"
  }, {
    path: "/sv/barcodeInfo",
    component: _95eeb358,
    name: "barcodeInfo___sv"
  }, {
    path: "/sv/book-a-chat",
    component: _199a78ae,
    name: "book-a-chat___sv"
  }, {
    path: "/sv/christmas-gifts",
    component: _74b69784,
    name: "christmas-gifts___sv"
  }, {
    path: "/sv/christmaswineinvestment",
    component: _127808b8,
    name: "christmaswineinvestment___sv"
  }, {
    path: "/sv/collections",
    component: _4e64555e,
    name: "collections___sv"
  }, {
    path: "/sv/collections-cart",
    component: _78c65e42,
    name: "collections-cart___sv"
  }, {
    path: "/sv/contact-us",
    component: _521bf555,
    name: "contact-us___sv"
  }, {
    path: "/sv/cookie-policy",
    component: _73e24fc1,
    name: "cookie-policy___sv"
  }, {
    path: "/sv/dryjanuarywineinvestment",
    component: _849eb7b6,
    name: "dryjanuarywineinvestment___sv"
  }, {
    path: "/sv/en-primeur",
    component: _4d487ba2,
    name: "en-primeur___sv"
  }, {
    path: "/sv/enter-amount",
    component: _6777a957,
    name: "enter-amount___sv"
  }, {
    path: "/sv/failed-bid",
    component: _d97eb752,
    name: "failed-bid___sv"
  }, {
    path: "/sv/failed-buy",
    component: _ae3a8d40,
    name: "failed-buy___sv"
  }, {
    path: "/sv/failed-invoice",
    component: _55193cf2,
    name: "failed-invoice___sv"
  }, {
    path: "/sv/faq",
    component: _ca324bc8,
    name: "faq___sv",
    children: [{
      path: "about-vindome",
      component: _c6cd30ea,
      name: "faq-about-vindome___sv"
    }, {
      path: "billing",
      component: _1a0edf88,
      name: "faq-billing___sv"
    }, {
      path: "buy-and-sell",
      component: _490a9532,
      name: "faq-buy-and-sell___sv"
    }, {
      path: "delivery",
      component: _b0261992,
      name: "faq-delivery___sv"
    }, {
      path: "my-account",
      component: _7f699b8f,
      name: "faq-my-account___sv"
    }, {
      path: "my-portfolio",
      component: _d5c032ac,
      name: "faq-my-portfolio___sv"
    }, {
      path: "storage",
      component: _158a00f0,
      name: "faq-storage___sv"
    }, {
      path: "using-the-app",
      component: _0a0dd96e,
      name: "faq-using-the-app___sv"
    }, {
      path: "wine-investment",
      component: _4d4dedb8,
      name: "faq-wine-investment___sv"
    }, {
      path: ":id?",
      component: _4c34b9c7,
      name: "faq-id___sv"
    }]
  }, {
    path: "/sv/forgot-password",
    component: _0417bc8b,
    name: "forgot-password___sv"
  }, {
    path: "/sv/gettheapp",
    component: _04bdb0ef,
    name: "gettheapp___sv"
  }, {
    path: "/sv/how-it-works",
    component: _6d0969a7,
    name: "how-it-works___sv"
  }, {
    path: "/sv/imprint",
    component: _3466728f,
    name: "imprint___sv"
  }, {
    path: "/sv/ingrid-brodin",
    component: _665ebeb2,
    name: "ingrid-brodin___sv"
  }, {
    path: "/sv/investment-tips",
    component: _5d26d9f8,
    name: "investment-tips___sv"
  }, {
    path: "/sv/invoices-cart",
    component: _63d406ad,
    name: "invoices-cart___sv"
  }, {
    path: "/sv/login",
    component: _10ba8d22,
    name: "login___sv"
  }, {
    path: "/sv/login-pending",
    component: _6823ea39,
    name: "login-pending___sv"
  }, {
    path: "/sv/lp-activation-2",
    component: _a18e6c2c,
    name: "lp-activation-2___sv"
  }, {
    path: "/sv/lp-brand-message",
    component: _8bfafdbc,
    name: "lp-brand-message___sv"
  }, {
    path: "/sv/lp-brand-message-2",
    component: _3dd3b1c7,
    name: "lp-brand-message-2___sv"
  }, {
    path: "/sv/lp-campaign-for-investors",
    component: _4f0ba549,
    name: "lp-campaign-for-investors___sv"
  }, {
    path: "/sv/lp-campaign-for-investors-3",
    component: _959b00a2,
    name: "lp-campaign-for-investors-3___sv"
  }, {
    path: "/sv/lp-campaign-for-investors-ii",
    component: _5061ddd4,
    name: "lp-campaign-for-investors-ii___sv"
  }, {
    path: "/sv/lp-campaign-for-wine-lovers",
    component: _c5bb0b36,
    name: "lp-campaign-for-wine-lovers___sv"
  }, {
    path: "/sv/lp-christmas-campaign-for-investors",
    component: _84247c98,
    name: "lp-christmas-campaign-for-investors___sv"
  }, {
    path: "/sv/lp-christmas-gifts",
    component: _49bb82d9,
    name: "lp-christmas-gifts___sv"
  }, {
    path: "/sv/lp-collections",
    component: _35cb2336,
    name: "lp-collections___sv"
  }, {
    path: "/sv/lp-collections-2",
    component: _2c07c24a,
    name: "lp-collections-2___sv"
  }, {
    path: "/sv/lp-collections-3",
    component: _2beb9348,
    name: "lp-collections-3___sv"
  }, {
    path: "/sv/lp-collections-4",
    component: _2bcf6446,
    name: "lp-collections-4___sv"
  }, {
    path: "/sv/lp-en-primeur",
    component: _6e688fb2,
    name: "lp-en-primeur___sv"
  }, {
    path: "/sv/lp-en-primeur-2",
    component: _81a988e8,
    name: "lp-en-primeur-2___sv"
  }, {
    path: "/sv/lp-en-primeur-2021",
    component: _79f40013,
    name: "lp-en-primeur-2021___sv"
  }, {
    path: "/sv/lp-en-primeur-3",
    component: _818d59e6,
    name: "lp-en-primeur-3___sv"
  }, {
    path: "/sv/lp-en-primeur-4",
    component: _81712ae4,
    name: "lp-en-primeur-4___sv"
  }, {
    path: "/sv/lp-en-primeur-II-2021",
    component: _fc759138,
    name: "lp-en-primeur-II-2021___sv"
  }, {
    path: "/sv/lp-en-primeur-purchase",
    component: _d39b81d2,
    name: "lp-en-primeur-purchase___sv"
  }, {
    path: "/sv/lp-en-primeur-purchase-2",
    component: _65e87308,
    name: "lp-en-primeur-purchase-2___sv"
  }, {
    path: "/sv/lp-en-primeur-purchase-3",
    component: _65cc4406,
    name: "lp-en-primeur-purchase-3___sv"
  }, {
    path: "/sv/lp-en-primeur-purchase-4",
    component: _65b01504,
    name: "lp-en-primeur-purchase-4___sv"
  }, {
    path: "/sv/lp-en-primeur-purchase-campaign",
    component: _69d1e2b6,
    name: "lp-en-primeur-purchase-campaign___sv"
  }, {
    path: "/sv/lp-en-primeur-purchase-campaign-II",
    component: _492bed72,
    name: "lp-en-primeur-purchase-campaign-II___sv"
  }, {
    path: "/sv/lp-invest-registration",
    component: _5a0c4a32,
    name: "lp-invest-registration___sv"
  }, {
    path: "/sv/lp-invest-registration-2",
    component: _028f76d7,
    name: "lp-invest-registration-2___sv"
  }, {
    path: "/sv/lp-invest-registration-3",
    component: _029d8e58,
    name: "lp-invest-registration-3___sv"
  }, {
    path: "/sv/lp-invest-registration-4",
    component: _02aba5d9,
    name: "lp-invest-registration-4___sv"
  }, {
    path: "/sv/lp-investment-like-no-other-i",
    component: _3a1819cb,
    name: "lp-investment-like-no-other-i___sv"
  }, {
    path: "/sv/lp-investment-like-no-other-ii",
    component: _0c09d5ae,
    name: "lp-investment-like-no-other-ii___sv"
  }, {
    path: "/sv/lp-investment-like-no-other-iii",
    component: _784f962b,
    name: "lp-investment-like-no-other-iii___sv"
  }, {
    path: "/sv/lp-investor",
    component: _4894a247,
    name: "lp-investor___sv"
  }, {
    path: "/sv/lp-investor2",
    component: _6bd1600a,
    name: "lp-investor2___sv"
  }, {
    path: "/sv/lp-live-market-purchase",
    component: _31dd5f40,
    name: "lp-live-market-purchase___sv"
  }, {
    path: "/sv/lp-live-market-purchase-2",
    component: _2a6f8065,
    name: "lp-live-market-purchase-2___sv"
  }, {
    path: "/sv/lp-lover",
    component: _74551bbe,
    name: "lp-lover___sv"
  }, {
    path: "/sv/lp-opportunities-for-investors",
    component: _71385548,
    name: "lp-opportunities-for-investors___sv"
  }, {
    path: "/sv/lp-opportunities-for-investors-ii",
    component: _22375e3e,
    name: "lp-opportunities-for-investors-ii___sv"
  }, {
    path: "/sv/lp-opportunities-for-wine-lovers",
    component: _602ef1b8,
    name: "lp-opportunities-for-wine-lovers___sv"
  }, {
    path: "/sv/lp-portfolio-performance",
    component: _0992268c,
    name: "lp-portfolio-performance___sv"
  }, {
    path: "/sv/lp-portfolio-performance-2",
    component: _2fe0b69e,
    name: "lp-portfolio-performance-2___sv"
  }, {
    path: "/sv/lp-thegraceclub-displaystand-learnmore",
    component: _a04a299e,
    name: "lp-thegraceclub-displaystand-learnmore___sv"
  }, {
    path: "/sv/lp-wine-investment-made-easy",
    component: _465060e5,
    name: "lp-wine-investment-made-easy___sv"
  }, {
    path: "/sv/mario-colesanti",
    component: _3598248d,
    name: "mario-colesanti___sv"
  }, {
    path: "/sv/my-account",
    component: _09d48279,
    name: "my-account___sv"
  }, {
    path: "/sv/news",
    component: _3b011b86,
    name: "news___sv"
  }, {
    path: "/sv/notifications",
    component: _5172b96e,
    name: "notifications___sv"
  }, {
    path: "/sv/payment-mango",
    component: _58649f7e,
    name: "payment-mango___sv"
  }, {
    path: "/sv/paypal-authorize",
    component: _166a980e,
    name: "paypal-authorize___sv"
  }, {
    path: "/sv/paypal-fail",
    component: _9d8c4c84,
    name: "paypal-fail___sv"
  }, {
    path: "/sv/paypal-success",
    component: _4fc925da,
    name: "paypal-success___sv"
  }, {
    path: "/sv/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy___sv"
  }, {
    path: "/sv/register",
    component: _5fba350d,
    name: "register___sv"
  }, {
    path: "/sv/registration-active",
    component: _1f17bfa0,
    name: "registration-active___sv"
  }, {
    path: "/sv/registration-device",
    component: _58599220,
    name: "registration-device___sv"
  }, {
    path: "/sv/registration-expired",
    component: _00b2430a,
    name: "registration-expired___sv"
  }, {
    path: "/sv/registration-pending",
    component: _478637ed,
    name: "registration-pending___sv"
  }, {
    path: "/sv/registration-success",
    component: _8cdc4f4e,
    name: "registration-success___sv"
  }, {
    path: "/sv/reset-password",
    component: _bb1ec17a,
    name: "reset-password___sv"
  }, {
    path: "/sv/saturnalia-info",
    component: _89102e5a,
    name: "saturnalia-info___sv"
  }, {
    path: "/sv/saturnalia-info-iframe",
    component: _56c9dee4,
    name: "saturnalia-info-iframe___sv"
  }, {
    path: "/sv/start-investing",
    component: _148c7fb0,
    name: "start-investing___sv"
  }, {
    path: "/sv/stripe-refresh",
    component: _31c15e45,
    name: "stripe-refresh___sv"
  }, {
    path: "/sv/stripe-return",
    component: _b3047194,
    name: "stripe-return___sv"
  }, {
    path: "/sv/subscribe-en-primeur",
    component: _5eb57005,
    name: "subscribe-en-primeur___sv"
  }, {
    path: "/sv/success-bid",
    component: _2075a2b9,
    name: "success-bid___sv"
  }, {
    path: "/sv/success-buy",
    component: _3617b7c2,
    name: "success-buy___sv"
  }, {
    path: "/sv/success-buy-full",
    component: _dc5bc42c,
    name: "success-buy-full___sv"
  }, {
    path: "/sv/success-collection",
    component: _c32f6fdc,
    name: "success-collection___sv"
  }, {
    path: "/sv/success-collection-delivery",
    component: _2c42ccff,
    name: "success-collection-delivery___sv"
  }, {
    path: "/sv/success-collection-full",
    component: _1ddf3f9a,
    name: "success-collection-full___sv"
  }, {
    path: "/sv/success-gift",
    component: _067002b8,
    name: "success-gift___sv"
  }, {
    path: "/sv/success-gift-delivery",
    component: _11125aad,
    name: "success-gift-delivery___sv"
  }, {
    path: "/sv/success-invoice",
    component: _ad43422e,
    name: "success-invoice___sv"
  }, {
    path: "/sv/support",
    component: _1c176355,
    name: "support___sv"
  }, {
    path: "/sv/tell-us-more",
    component: _616a0c1c,
    name: "tell-us-more___sv"
  }, {
    path: "/sv/terms-and-conditions",
    component: _18f50744,
    name: "terms-and-conditions___sv"
  }, {
    path: "/sv/trading-desk",
    component: _f59a091c,
    name: "trading-desk___sv"
  }, {
    path: "/sv/triplea-success",
    component: _81b0bf02,
    name: "triplea-success___sv"
  }, {
    path: "/sv/valentines-day",
    component: _91c1d014,
    name: "valentines-day___sv"
  }, {
    path: "/sv/verify-your-identity",
    component: _15fea1ae,
    name: "verify-your-identity___sv"
  }, {
    path: "/sv/wine-academy",
    component: _dca247dc,
    name: "wine-academy___sv"
  }, {
    path: "/sv/wine-collections-investor",
    component: _32d99a9a,
    name: "wine-collections-investor___sv"
  }, {
    path: "/sv/wine-collections-investor2",
    component: _28716208,
    name: "wine-collections-investor2___sv"
  }, {
    path: "/sv/wine-collections-investor3",
    component: _287f7989,
    name: "wine-collections-investor3___sv"
  }, {
    path: "/sv/wine-collections-investor4",
    component: _288d910a,
    name: "wine-collections-investor4___sv"
  }, {
    path: "/sv/wine-collections-lover",
    component: _44fc92ae,
    name: "wine-collections-lover___sv"
  }, {
    path: "/sv/wine-collector",
    component: _7a28e5b3,
    name: "wine-collector___sv"
  }, {
    path: "/sv/wine-collector2",
    component: _69e90be2,
    name: "wine-collector2___sv"
  }, {
    path: "/sv/wine-guide",
    component: _509300a5,
    name: "wine-guide___sv"
  }, {
    path: "/sv/wine-investor",
    component: _0dfd473c,
    name: "wine-investor___sv"
  }, {
    path: "/sv/wine-investor2",
    component: _2741d940,
    name: "wine-investor2___sv"
  }, {
    path: "/sv/wine-investors-registration",
    component: _58aaf135,
    name: "wine-investors-registration___sv"
  }, {
    path: "/sv/wine-investors-registration2",
    component: _866a4266,
    name: "wine-investors-registration2___sv"
  }, {
    path: "/sv/wine-lovers-registration",
    component: _22e203b9,
    name: "wine-lovers-registration___sv"
  }, {
    path: "/sv/wine-lovers-registration2",
    component: _39761cc9,
    name: "wine-lovers-registration2___sv"
  }, {
    path: "/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___en"
  }, {
    path: "/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___en"
  }, {
    path: "/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___en"
  }, {
    path: "/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___en"
  }, {
    path: "/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___en"
  }, {
    path: "/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___en"
  }, {
    path: "/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___en"
  }, {
    path: "/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___en"
  }, {
    path: "/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___en"
  }, {
    path: "/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___en"
  }, {
    path: "/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___en"
  }, {
    path: "/de/home/collections",
    component: _308d03cf,
    name: "home-collections___de"
  }, {
    path: "/de/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___de"
  }, {
    path: "/de/home/live",
    component: _7bb0d1a2,
    name: "home-live___de"
  }, {
    path: "/de/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___de"
  }, {
    path: "/de/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___de"
  }, {
    path: "/de/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___de"
  }, {
    path: "/de/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___de"
  }, {
    path: "/de/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___de"
  }, {
    path: "/de/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___de"
  }, {
    path: "/de/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___de"
  }, {
    path: "/de/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___de"
  }, {
    path: "/de/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___de"
  }, {
    path: "/de/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___de"
  }, {
    path: "/de/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___de"
  }, {
    path: "/de/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___de"
  }, {
    path: "/es/home/collections",
    component: _308d03cf,
    name: "home-collections___es"
  }, {
    path: "/es/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___es"
  }, {
    path: "/es/home/live",
    component: _7bb0d1a2,
    name: "home-live___es"
  }, {
    path: "/es/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___es"
  }, {
    path: "/es/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___es"
  }, {
    path: "/es/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___es"
  }, {
    path: "/es/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___es"
  }, {
    path: "/es/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___es"
  }, {
    path: "/es/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___es"
  }, {
    path: "/es/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___es"
  }, {
    path: "/es/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___es"
  }, {
    path: "/es/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___es"
  }, {
    path: "/es/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___es"
  }, {
    path: "/es/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___es"
  }, {
    path: "/es/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___es"
  }, {
    path: "/fr/home/collections",
    component: _308d03cf,
    name: "home-collections___fr"
  }, {
    path: "/fr/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___fr"
  }, {
    path: "/fr/home/live",
    component: _7bb0d1a2,
    name: "home-live___fr"
  }, {
    path: "/fr/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___fr"
  }, {
    path: "/fr/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___fr"
  }, {
    path: "/fr/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___fr"
  }, {
    path: "/fr/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___fr"
  }, {
    path: "/fr/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___fr"
  }, {
    path: "/fr/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___fr"
  }, {
    path: "/fr/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___fr"
  }, {
    path: "/fr/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___fr"
  }, {
    path: "/fr/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___fr"
  }, {
    path: "/fr/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___fr"
  }, {
    path: "/fr/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___fr"
  }, {
    path: "/fr/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___fr"
  }, {
    path: "/it/home/collections",
    component: _308d03cf,
    name: "home-collections___it"
  }, {
    path: "/it/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___it"
  }, {
    path: "/it/home/live",
    component: _7bb0d1a2,
    name: "home-live___it"
  }, {
    path: "/it/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___it"
  }, {
    path: "/it/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___it"
  }, {
    path: "/it/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___it"
  }, {
    path: "/it/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___it"
  }, {
    path: "/it/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___it"
  }, {
    path: "/it/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___it"
  }, {
    path: "/it/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___it"
  }, {
    path: "/it/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___it"
  }, {
    path: "/it/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___it"
  }, {
    path: "/it/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___it"
  }, {
    path: "/it/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___it"
  }, {
    path: "/it/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___it"
  }, {
    path: "/ru/home/collections",
    component: _308d03cf,
    name: "home-collections___ru"
  }, {
    path: "/ru/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___ru"
  }, {
    path: "/ru/home/live",
    component: _7bb0d1a2,
    name: "home-live___ru"
  }, {
    path: "/ru/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___ru"
  }, {
    path: "/ru/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___ru"
  }, {
    path: "/ru/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___ru"
  }, {
    path: "/ru/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___ru"
  }, {
    path: "/ru/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___ru"
  }, {
    path: "/ru/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___ru"
  }, {
    path: "/ru/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___ru"
  }, {
    path: "/ru/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___ru"
  }, {
    path: "/ru/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___ru"
  }, {
    path: "/ru/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___ru"
  }, {
    path: "/ru/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___ru"
  }, {
    path: "/ru/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___ru"
  }, {
    path: "/sv/home/collections",
    component: _308d03cf,
    name: "home-collections___sv"
  }, {
    path: "/sv/home/enprimeur",
    component: _2202ff66,
    name: "home-enprimeur___sv"
  }, {
    path: "/sv/home/live",
    component: _7bb0d1a2,
    name: "home-live___sv"
  }, {
    path: "/sv/lnp/en-primeur",
    component: _74521fa3,
    name: "lnp-en-primeur___sv"
  }, {
    path: "/sv/wine-guide/argentina",
    component: _731af086,
    name: "wine-guide-argentina___sv"
  }, {
    path: "/sv/wine-guide/australia",
    component: _5cb32116,
    name: "wine-guide-australia___sv"
  }, {
    path: "/sv/wine-guide/chile",
    component: _24c43c1a,
    name: "wine-guide-chile___sv"
  }, {
    path: "/sv/wine-guide/france",
    component: _d86bfa4a,
    name: "wine-guide-france___sv"
  }, {
    path: "/sv/wine-guide/germany",
    component: _6d9b3b6e,
    name: "wine-guide-germany___sv"
  }, {
    path: "/sv/wine-guide/italy",
    component: _6624d40e,
    name: "wine-guide-italy___sv"
  }, {
    path: "/sv/wine-guide/new-zealand",
    component: _5354862a,
    name: "wine-guide-new-zealand___sv"
  }, {
    path: "/sv/wine-guide/portugal",
    component: _42e4d3fe,
    name: "wine-guide-portugal___sv"
  }, {
    path: "/sv/wine-guide/south-africa",
    component: _7c518a8c,
    name: "wine-guide-south-africa___sv"
  }, {
    path: "/sv/wine-guide/spain",
    component: _4b1ff79f,
    name: "wine-guide-spain___sv"
  }, {
    path: "/sv/wine-guide/usa",
    component: _5c862d99,
    name: "wine-guide-usa___sv"
  }, {
    path: "/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___en"
  }, {
    path: "/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___en"
  }, {
    path: "/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___en"
  }, {
    path: "/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___en"
  }, {
    path: "/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___en"
  }, {
    path: "/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___en"
  }, {
    path: "/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___en"
  }, {
    path: "/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___en"
  }, {
    path: "/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___en"
  }, {
    path: "/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___en"
  }, {
    path: "/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___en"
  }, {
    path: "/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___en"
  }, {
    path: "/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___en"
  }, {
    path: "/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___en"
  }, {
    path: "/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___en"
  }, {
    path: "/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___en"
  }, {
    path: "/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___en"
  }, {
    path: "/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___en"
  }, {
    path: "/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___en"
  }, {
    path: "/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___en"
  }, {
    path: "/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___en"
  }, {
    path: "/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___en"
  }, {
    path: "/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___en"
  }, {
    path: "/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___en"
  }, {
    path: "/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___en"
  }, {
    path: "/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___en"
  }, {
    path: "/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___en"
  }, {
    path: "/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___en"
  }, {
    path: "/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___en"
  }, {
    path: "/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___en"
  }, {
    path: "/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___en"
  }, {
    path: "/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___en"
  }, {
    path: "/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___en"
  }, {
    path: "/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___en"
  }, {
    path: "/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___en"
  }, {
    path: "/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___en"
  }, {
    path: "/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___en"
  }, {
    path: "/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___en"
  }, {
    path: "/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___en"
  }, {
    path: "/de/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___de"
  }, {
    path: "/de/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___de"
  }, {
    path: "/de/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___de"
  }, {
    path: "/de/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___de"
  }, {
    path: "/de/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___de"
  }, {
    path: "/de/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___de"
  }, {
    path: "/de/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___de"
  }, {
    path: "/de/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___de"
  }, {
    path: "/de/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___de"
  }, {
    path: "/de/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___de"
  }, {
    path: "/de/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___de"
  }, {
    path: "/de/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___de"
  }, {
    path: "/de/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___de"
  }, {
    path: "/de/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___de"
  }, {
    path: "/de/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___de"
  }, {
    path: "/de/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___de"
  }, {
    path: "/de/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___de"
  }, {
    path: "/de/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___de"
  }, {
    path: "/de/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___de"
  }, {
    path: "/de/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___de"
  }, {
    path: "/de/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___de"
  }, {
    path: "/de/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___de"
  }, {
    path: "/de/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___de"
  }, {
    path: "/de/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___de"
  }, {
    path: "/de/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___de"
  }, {
    path: "/de/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___de"
  }, {
    path: "/de/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___de"
  }, {
    path: "/de/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___de"
  }, {
    path: "/de/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___de"
  }, {
    path: "/de/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___de"
  }, {
    path: "/de/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___de"
  }, {
    path: "/de/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___de"
  }, {
    path: "/de/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___de"
  }, {
    path: "/de/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___de"
  }, {
    path: "/de/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___de"
  }, {
    path: "/de/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___de"
  }, {
    path: "/de/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___de"
  }, {
    path: "/de/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___de"
  }, {
    path: "/es/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___es"
  }, {
    path: "/es/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___es"
  }, {
    path: "/es/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___es"
  }, {
    path: "/es/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___es"
  }, {
    path: "/es/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___es"
  }, {
    path: "/es/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___es"
  }, {
    path: "/es/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___es"
  }, {
    path: "/es/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___es"
  }, {
    path: "/es/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___es"
  }, {
    path: "/es/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___es"
  }, {
    path: "/es/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___es"
  }, {
    path: "/es/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___es"
  }, {
    path: "/es/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___es"
  }, {
    path: "/es/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___es"
  }, {
    path: "/es/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___es"
  }, {
    path: "/es/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___es"
  }, {
    path: "/es/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___es"
  }, {
    path: "/es/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___es"
  }, {
    path: "/es/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___es"
  }, {
    path: "/es/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___es"
  }, {
    path: "/es/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___es"
  }, {
    path: "/es/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___es"
  }, {
    path: "/es/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___es"
  }, {
    path: "/es/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___es"
  }, {
    path: "/es/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___es"
  }, {
    path: "/es/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___es"
  }, {
    path: "/es/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___es"
  }, {
    path: "/es/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___es"
  }, {
    path: "/es/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___es"
  }, {
    path: "/es/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___es"
  }, {
    path: "/es/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___es"
  }, {
    path: "/es/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___es"
  }, {
    path: "/es/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___es"
  }, {
    path: "/es/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___es"
  }, {
    path: "/es/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___es"
  }, {
    path: "/es/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___es"
  }, {
    path: "/es/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___es"
  }, {
    path: "/es/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___es"
  }, {
    path: "/fr/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___fr"
  }, {
    path: "/fr/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___fr"
  }, {
    path: "/fr/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___fr"
  }, {
    path: "/fr/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___fr"
  }, {
    path: "/fr/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___fr"
  }, {
    path: "/fr/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___fr"
  }, {
    path: "/fr/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___fr"
  }, {
    path: "/fr/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___fr"
  }, {
    path: "/fr/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___fr"
  }, {
    path: "/fr/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___fr"
  }, {
    path: "/fr/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___fr"
  }, {
    path: "/fr/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___fr"
  }, {
    path: "/fr/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___fr"
  }, {
    path: "/fr/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___fr"
  }, {
    path: "/fr/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___fr"
  }, {
    path: "/fr/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___fr"
  }, {
    path: "/fr/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___fr"
  }, {
    path: "/fr/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___fr"
  }, {
    path: "/fr/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___fr"
  }, {
    path: "/fr/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___fr"
  }, {
    path: "/fr/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___fr"
  }, {
    path: "/fr/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___fr"
  }, {
    path: "/fr/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___fr"
  }, {
    path: "/fr/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___fr"
  }, {
    path: "/fr/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___fr"
  }, {
    path: "/fr/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___fr"
  }, {
    path: "/fr/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___fr"
  }, {
    path: "/it/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___it"
  }, {
    path: "/it/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___it"
  }, {
    path: "/it/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___it"
  }, {
    path: "/it/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___it"
  }, {
    path: "/it/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___it"
  }, {
    path: "/it/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___it"
  }, {
    path: "/it/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___it"
  }, {
    path: "/it/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___it"
  }, {
    path: "/it/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___it"
  }, {
    path: "/it/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___it"
  }, {
    path: "/it/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___it"
  }, {
    path: "/it/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___it"
  }, {
    path: "/it/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___it"
  }, {
    path: "/it/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___it"
  }, {
    path: "/it/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___it"
  }, {
    path: "/it/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___it"
  }, {
    path: "/it/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___it"
  }, {
    path: "/it/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___it"
  }, {
    path: "/it/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___it"
  }, {
    path: "/it/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___it"
  }, {
    path: "/it/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___it"
  }, {
    path: "/it/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___it"
  }, {
    path: "/it/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___it"
  }, {
    path: "/it/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___it"
  }, {
    path: "/it/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___it"
  }, {
    path: "/it/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___it"
  }, {
    path: "/it/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___it"
  }, {
    path: "/it/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___it"
  }, {
    path: "/it/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___it"
  }, {
    path: "/it/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___it"
  }, {
    path: "/it/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___it"
  }, {
    path: "/it/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___it"
  }, {
    path: "/it/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___it"
  }, {
    path: "/it/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___it"
  }, {
    path: "/it/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___it"
  }, {
    path: "/it/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___it"
  }, {
    path: "/it/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___it"
  }, {
    path: "/it/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___it"
  }, {
    path: "/ru/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___ru"
  }, {
    path: "/ru/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___ru"
  }, {
    path: "/ru/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___ru"
  }, {
    path: "/ru/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___ru"
  }, {
    path: "/ru/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___ru"
  }, {
    path: "/ru/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___ru"
  }, {
    path: "/ru/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___ru"
  }, {
    path: "/ru/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___ru"
  }, {
    path: "/ru/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___ru"
  }, {
    path: "/ru/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___ru"
  }, {
    path: "/ru/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___ru"
  }, {
    path: "/ru/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___ru"
  }, {
    path: "/ru/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___ru"
  }, {
    path: "/ru/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___ru"
  }, {
    path: "/ru/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___ru"
  }, {
    path: "/ru/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___ru"
  }, {
    path: "/ru/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___ru"
  }, {
    path: "/ru/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___ru"
  }, {
    path: "/ru/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___ru"
  }, {
    path: "/ru/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___ru"
  }, {
    path: "/ru/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___ru"
  }, {
    path: "/ru/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___ru"
  }, {
    path: "/ru/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___ru"
  }, {
    path: "/ru/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___ru"
  }, {
    path: "/ru/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___ru"
  }, {
    path: "/ru/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___ru"
  }, {
    path: "/ru/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___ru"
  }, {
    path: "/sv/wine-guide/argentina/mendoza",
    component: _2a57c194,
    name: "wine-guide-argentina-mendoza___sv"
  }, {
    path: "/sv/wine-guide/argentina/patagonia",
    component: _76aca36e,
    name: "wine-guide-argentina-patagonia___sv"
  }, {
    path: "/sv/wine-guide/argentina/salta",
    component: _7589698e,
    name: "wine-guide-argentina-salta___sv"
  }, {
    path: "/sv/wine-guide/australia/barrossa-valley",
    component: _79647242,
    name: "wine-guide-australia-barrossa-valley___sv"
  }, {
    path: "/sv/wine-guide/australia/clare-valley",
    component: _ea582660,
    name: "wine-guide-australia-clare-valley___sv"
  }, {
    path: "/sv/wine-guide/australia/eden-valley",
    component: _4a36a3ea,
    name: "wine-guide-australia-eden-valley___sv"
  }, {
    path: "/sv/wine-guide/australia/mclaren-valley",
    component: _285b5c8b,
    name: "wine-guide-australia-mclaren-valley___sv"
  }, {
    path: "/sv/wine-guide/australia/yarra-valley",
    component: _4191c47c,
    name: "wine-guide-australia-yarra-valley___sv"
  }, {
    path: "/sv/wine-guide/chile/aconcagua",
    component: _259c0d98,
    name: "wine-guide-chile-aconcagua___sv"
  }, {
    path: "/sv/wine-guide/chile/colchagua",
    component: _15ce5b3b,
    name: "wine-guide-chile-colchagua___sv"
  }, {
    path: "/sv/wine-guide/chile/maipo",
    component: _4ecd7610,
    name: "wine-guide-chile-maipo___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux",
    component: _49e77848,
    name: "wine-guide-france-bordeaux___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy",
    component: _efdcde00,
    name: "wine-guide-france-burgundy___sv"
  }, {
    path: "/sv/wine-guide/france/champagne",
    component: _5f900368,
    name: "wine-guide-france-champagne___sv"
  }, {
    path: "/sv/wine-guide/france/cognac",
    component: _a16f2108,
    name: "wine-guide-france-cognac___sv"
  }, {
    path: "/sv/wine-guide/france/jura",
    component: _280887c1,
    name: "wine-guide-france-jura___sv"
  }, {
    path: "/sv/wine-guide/france/languedoc-roussillon",
    component: _6a8a74ca,
    name: "wine-guide-france-languedoc-roussillon___sv"
  }, {
    path: "/sv/wine-guide/france/provence",
    component: _b650df8a,
    name: "wine-guide-france-provence___sv"
  }, {
    path: "/sv/wine-guide/france/rhone",
    component: _ea26e188,
    name: "wine-guide-france-rhone___sv"
  }, {
    path: "/sv/wine-guide/germany/ahr",
    component: _8c354236,
    name: "wine-guide-germany-ahr___sv"
  }, {
    path: "/sv/wine-guide/germany/baden",
    component: _29a22da8,
    name: "wine-guide-germany-baden___sv"
  }, {
    path: "/sv/wine-guide/germany/franken",
    component: _5e647816,
    name: "wine-guide-germany-franken___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer",
    component: _31040684,
    name: "wine-guide-germany-mosel-saar-ruwer___sv"
  }, {
    path: "/sv/wine-guide/germany/rheinhessen",
    component: _e303ee58,
    name: "wine-guide-germany-rheinhessen___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont",
    component: _6cf5c3ac,
    name: "wine-guide-italy-piedmont___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany",
    component: _7f8c11a5,
    name: "wine-guide-italy-tuscany___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/ata-rangi",
    component: _1e822acc,
    name: "wine-guide-new-zealand-ata-rangi___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/bell-hill-vineyards",
    component: _0e361865,
    name: "wine-guide-new-zealand-bell-hill-vineyards___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/craggy-range",
    component: _695e1862,
    name: "wine-guide-new-zealand-craggy-range___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/dog-point-vineyards",
    component: _6995da59,
    name: "wine-guide-new-zealand-dog-point-vineyards___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/millton-vineyards-and-winery",
    component: _30ab8472,
    name: "wine-guide-new-zealand-millton-vineyards-and-winery___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/pyramid-valley-vineyards",
    component: _6e929e54,
    name: "wine-guide-new-zealand-pyramid-valley-vineyards___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/rippon-vineyards",
    component: _57a4ab70,
    name: "wine-guide-new-zealand-rippon-vineyards___sv"
  }, {
    path: "/sv/wine-guide/new-zealand/trinity-hill",
    component: _2f40db0a,
    name: "wine-guide-new-zealand-trinity-hill___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley",
    component: _561a62ac,
    name: "wine-guide-portugal-douro-valley___sv"
  }, {
    path: "/sv/wine-guide/south-africa/constantia",
    component: _64e39987,
    name: "wine-guide-south-africa-constantia___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch",
    component: _1faf9dc9,
    name: "wine-guide-south-africa-stellenbosch___sv"
  }, {
    path: "/sv/wine-guide/spain/priorat",
    component: _182286ec,
    name: "wine-guide-spain-priorat___sv"
  }, {
    path: "/sv/wine-guide/spain/ribera-del-duero",
    component: _39b77f51,
    name: "wine-guide-spain-ribera-del-duero___sv"
  }, {
    path: "/sv/wine-guide/usa/napa",
    component: _7304af30,
    name: "wine-guide-usa-napa___sv"
  }, {
    path: "/sv/wine-guide/usa/sonoma",
    component: _3dc60f3d,
    name: "wine-guide-usa-sonoma___sv"
  }, {
    path: "/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___en"
  }, {
    path: "/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___en"
  }, {
    path: "/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___en"
  }, {
    path: "/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___en"
  }, {
    path: "/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___en"
  }, {
    path: "/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___en"
  }, {
    path: "/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___en"
  }, {
    path: "/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___en"
  }, {
    path: "/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___en"
  }, {
    path: "/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___en"
  }, {
    path: "/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___en"
  }, {
    path: "/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___en"
  }, {
    path: "/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___en"
  }, {
    path: "/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___en"
  }, {
    path: "/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___en"
  }, {
    path: "/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___en"
  }, {
    path: "/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___en"
  }, {
    path: "/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___en"
  }, {
    path: "/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___en"
  }, {
    path: "/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___en"
  }, {
    path: "/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___en"
  }, {
    path: "/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___en"
  }, {
    path: "/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___en"
  }, {
    path: "/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___en"
  }, {
    path: "/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___en"
  }, {
    path: "/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___en"
  }, {
    path: "/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___en"
  }, {
    path: "/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___en"
  }, {
    path: "/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___en"
  }, {
    path: "/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___en"
  }, {
    path: "/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___en"
  }, {
    path: "/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___en"
  }, {
    path: "/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___en"
  }, {
    path: "/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___en"
  }, {
    path: "/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___en"
  }, {
    path: "/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___en"
  }, {
    path: "/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___en"
  }, {
    path: "/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___en"
  }, {
    path: "/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___en"
  }, {
    path: "/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___en"
  }, {
    path: "/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___en"
  }, {
    path: "/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___en"
  }, {
    path: "/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___en"
  }, {
    path: "/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___en"
  }, {
    path: "/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___en"
  }, {
    path: "/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___en"
  }, {
    path: "/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___en"
  }, {
    path: "/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___en"
  }, {
    path: "/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___en"
  }, {
    path: "/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___en"
  }, {
    path: "/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___en"
  }, {
    path: "/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___en"
  }, {
    path: "/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___en"
  }, {
    path: "/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___en"
  }, {
    path: "/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___en"
  }, {
    path: "/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___en"
  }, {
    path: "/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___en"
  }, {
    path: "/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___en"
  }, {
    path: "/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___en"
  }, {
    path: "/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___en"
  }, {
    path: "/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___en"
  }, {
    path: "/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___en"
  }, {
    path: "/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___en"
  }, {
    path: "/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___en"
  }, {
    path: "/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___en"
  }, {
    path: "/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___en"
  }, {
    path: "/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___en"
  }, {
    path: "/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___en"
  }, {
    path: "/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___en"
  }, {
    path: "/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___en"
  }, {
    path: "/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___en"
  }, {
    path: "/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___en"
  }, {
    path: "/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___en"
  }, {
    path: "/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___en"
  }, {
    path: "/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___en"
  }, {
    path: "/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___en"
  }, {
    path: "/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___en"
  }, {
    path: "/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___en"
  }, {
    path: "/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___en"
  }, {
    path: "/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___en"
  }, {
    path: "/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___en"
  }, {
    path: "/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___en"
  }, {
    path: "/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___en"
  }, {
    path: "/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___en"
  }, {
    path: "/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___en"
  }, {
    path: "/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___en"
  }, {
    path: "/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___en"
  }, {
    path: "/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___en"
  }, {
    path: "/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___en"
  }, {
    path: "/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___en"
  }, {
    path: "/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___en"
  }, {
    path: "/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___en"
  }, {
    path: "/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___en"
  }, {
    path: "/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___en"
  }, {
    path: "/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___en"
  }, {
    path: "/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___en"
  }, {
    path: "/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___en"
  }, {
    path: "/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___en"
  }, {
    path: "/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___en"
  }, {
    path: "/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___en"
  }, {
    path: "/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___en"
  }, {
    path: "/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___en"
  }, {
    path: "/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___en"
  }, {
    path: "/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___en"
  }, {
    path: "/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___en"
  }, {
    path: "/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___en"
  }, {
    path: "/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___en"
  }, {
    path: "/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___en"
  }, {
    path: "/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___en"
  }, {
    path: "/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___en"
  }, {
    path: "/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___en"
  }, {
    path: "/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___en"
  }, {
    path: "/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___en"
  }, {
    path: "/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___en"
  }, {
    path: "/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___en"
  }, {
    path: "/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___en"
  }, {
    path: "/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___en"
  }, {
    path: "/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___en"
  }, {
    path: "/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___en"
  }, {
    path: "/de/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___de"
  }, {
    path: "/de/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___de"
  }, {
    path: "/de/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___de"
  }, {
    path: "/de/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___de"
  }, {
    path: "/de/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___de"
  }, {
    path: "/de/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___de"
  }, {
    path: "/de/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___de"
  }, {
    path: "/de/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___de"
  }, {
    path: "/de/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___de"
  }, {
    path: "/de/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___de"
  }, {
    path: "/de/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___de"
  }, {
    path: "/de/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___de"
  }, {
    path: "/de/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___de"
  }, {
    path: "/de/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___de"
  }, {
    path: "/de/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___de"
  }, {
    path: "/de/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___de"
  }, {
    path: "/de/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___de"
  }, {
    path: "/de/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___de"
  }, {
    path: "/de/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___de"
  }, {
    path: "/de/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___de"
  }, {
    path: "/de/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___de"
  }, {
    path: "/de/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___de"
  }, {
    path: "/de/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___de"
  }, {
    path: "/de/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___de"
  }, {
    path: "/de/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___de"
  }, {
    path: "/de/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___de"
  }, {
    path: "/de/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___de"
  }, {
    path: "/de/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___de"
  }, {
    path: "/de/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___de"
  }, {
    path: "/de/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___de"
  }, {
    path: "/de/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___de"
  }, {
    path: "/de/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___de"
  }, {
    path: "/de/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___de"
  }, {
    path: "/de/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___de"
  }, {
    path: "/de/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___de"
  }, {
    path: "/de/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___de"
  }, {
    path: "/de/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___de"
  }, {
    path: "/de/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___de"
  }, {
    path: "/de/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___de"
  }, {
    path: "/de/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___de"
  }, {
    path: "/de/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___de"
  }, {
    path: "/de/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___de"
  }, {
    path: "/de/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___de"
  }, {
    path: "/de/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___de"
  }, {
    path: "/de/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___de"
  }, {
    path: "/de/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___de"
  }, {
    path: "/de/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___de"
  }, {
    path: "/de/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___de"
  }, {
    path: "/de/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___de"
  }, {
    path: "/de/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___de"
  }, {
    path: "/de/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___de"
  }, {
    path: "/de/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___de"
  }, {
    path: "/de/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___de"
  }, {
    path: "/de/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___de"
  }, {
    path: "/de/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___de"
  }, {
    path: "/de/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___de"
  }, {
    path: "/de/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___de"
  }, {
    path: "/de/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___de"
  }, {
    path: "/de/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___de"
  }, {
    path: "/de/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___de"
  }, {
    path: "/de/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___de"
  }, {
    path: "/de/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___de"
  }, {
    path: "/de/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___de"
  }, {
    path: "/de/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___de"
  }, {
    path: "/de/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___de"
  }, {
    path: "/de/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___de"
  }, {
    path: "/de/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___de"
  }, {
    path: "/de/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___de"
  }, {
    path: "/de/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___de"
  }, {
    path: "/de/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___de"
  }, {
    path: "/de/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___de"
  }, {
    path: "/de/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___de"
  }, {
    path: "/de/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___de"
  }, {
    path: "/es/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___es"
  }, {
    path: "/es/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___es"
  }, {
    path: "/es/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___es"
  }, {
    path: "/es/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___es"
  }, {
    path: "/es/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___es"
  }, {
    path: "/es/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___es"
  }, {
    path: "/es/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___es"
  }, {
    path: "/es/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___es"
  }, {
    path: "/es/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___es"
  }, {
    path: "/es/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___es"
  }, {
    path: "/es/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___es"
  }, {
    path: "/es/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___es"
  }, {
    path: "/es/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___es"
  }, {
    path: "/es/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___es"
  }, {
    path: "/es/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___es"
  }, {
    path: "/es/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___es"
  }, {
    path: "/es/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___es"
  }, {
    path: "/es/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___es"
  }, {
    path: "/es/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___es"
  }, {
    path: "/es/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___es"
  }, {
    path: "/es/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___es"
  }, {
    path: "/es/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___es"
  }, {
    path: "/es/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___es"
  }, {
    path: "/es/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___es"
  }, {
    path: "/es/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___es"
  }, {
    path: "/es/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___es"
  }, {
    path: "/es/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___es"
  }, {
    path: "/es/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___es"
  }, {
    path: "/es/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___es"
  }, {
    path: "/es/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___es"
  }, {
    path: "/es/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___es"
  }, {
    path: "/es/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___es"
  }, {
    path: "/es/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___es"
  }, {
    path: "/es/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___es"
  }, {
    path: "/es/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___es"
  }, {
    path: "/es/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___es"
  }, {
    path: "/es/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___es"
  }, {
    path: "/es/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___es"
  }, {
    path: "/es/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___es"
  }, {
    path: "/es/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___es"
  }, {
    path: "/es/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___es"
  }, {
    path: "/es/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___es"
  }, {
    path: "/es/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___es"
  }, {
    path: "/es/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___es"
  }, {
    path: "/es/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___es"
  }, {
    path: "/es/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___es"
  }, {
    path: "/es/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___es"
  }, {
    path: "/es/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___es"
  }, {
    path: "/es/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___es"
  }, {
    path: "/es/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___es"
  }, {
    path: "/es/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___es"
  }, {
    path: "/es/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___es"
  }, {
    path: "/es/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___es"
  }, {
    path: "/es/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___es"
  }, {
    path: "/es/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___es"
  }, {
    path: "/es/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___es"
  }, {
    path: "/es/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___es"
  }, {
    path: "/es/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___es"
  }, {
    path: "/es/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___es"
  }, {
    path: "/es/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___es"
  }, {
    path: "/es/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___es"
  }, {
    path: "/es/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___es"
  }, {
    path: "/es/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___es"
  }, {
    path: "/es/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___es"
  }, {
    path: "/es/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___es"
  }, {
    path: "/es/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___es"
  }, {
    path: "/es/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___es"
  }, {
    path: "/es/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___es"
  }, {
    path: "/es/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___es"
  }, {
    path: "/es/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___es"
  }, {
    path: "/es/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___es"
  }, {
    path: "/es/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___es"
  }, {
    path: "/es/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___es"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___fr"
  }, {
    path: "/fr/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___fr"
  }, {
    path: "/fr/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___fr"
  }, {
    path: "/fr/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___fr"
  }, {
    path: "/fr/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___fr"
  }, {
    path: "/fr/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___fr"
  }, {
    path: "/fr/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___fr"
  }, {
    path: "/fr/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___fr"
  }, {
    path: "/fr/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___fr"
  }, {
    path: "/fr/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___fr"
  }, {
    path: "/fr/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___fr"
  }, {
    path: "/fr/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___fr"
  }, {
    path: "/fr/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___fr"
  }, {
    path: "/fr/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___fr"
  }, {
    path: "/fr/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___fr"
  }, {
    path: "/fr/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___fr"
  }, {
    path: "/fr/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___fr"
  }, {
    path: "/fr/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___fr"
  }, {
    path: "/fr/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___fr"
  }, {
    path: "/fr/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___fr"
  }, {
    path: "/fr/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___fr"
  }, {
    path: "/fr/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___fr"
  }, {
    path: "/fr/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___fr"
  }, {
    path: "/fr/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___fr"
  }, {
    path: "/fr/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___fr"
  }, {
    path: "/fr/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___fr"
  }, {
    path: "/fr/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___fr"
  }, {
    path: "/fr/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___fr"
  }, {
    path: "/fr/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___fr"
  }, {
    path: "/fr/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___fr"
  }, {
    path: "/fr/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___fr"
  }, {
    path: "/fr/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___fr"
  }, {
    path: "/fr/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___fr"
  }, {
    path: "/fr/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___fr"
  }, {
    path: "/fr/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___fr"
  }, {
    path: "/fr/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___fr"
  }, {
    path: "/fr/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___fr"
  }, {
    path: "/fr/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___fr"
  }, {
    path: "/fr/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___fr"
  }, {
    path: "/fr/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___fr"
  }, {
    path: "/fr/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___fr"
  }, {
    path: "/it/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___it"
  }, {
    path: "/it/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___it"
  }, {
    path: "/it/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___it"
  }, {
    path: "/it/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___it"
  }, {
    path: "/it/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___it"
  }, {
    path: "/it/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___it"
  }, {
    path: "/it/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___it"
  }, {
    path: "/it/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___it"
  }, {
    path: "/it/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___it"
  }, {
    path: "/it/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___it"
  }, {
    path: "/it/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___it"
  }, {
    path: "/it/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___it"
  }, {
    path: "/it/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___it"
  }, {
    path: "/it/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___it"
  }, {
    path: "/it/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___it"
  }, {
    path: "/it/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___it"
  }, {
    path: "/it/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___it"
  }, {
    path: "/it/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___it"
  }, {
    path: "/it/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___it"
  }, {
    path: "/it/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___it"
  }, {
    path: "/it/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___it"
  }, {
    path: "/it/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___it"
  }, {
    path: "/it/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___it"
  }, {
    path: "/it/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___it"
  }, {
    path: "/it/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___it"
  }, {
    path: "/it/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___it"
  }, {
    path: "/it/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___it"
  }, {
    path: "/it/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___it"
  }, {
    path: "/it/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___it"
  }, {
    path: "/it/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___it"
  }, {
    path: "/it/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___it"
  }, {
    path: "/it/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___it"
  }, {
    path: "/it/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___it"
  }, {
    path: "/it/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___it"
  }, {
    path: "/it/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___it"
  }, {
    path: "/it/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___it"
  }, {
    path: "/it/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___it"
  }, {
    path: "/it/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___it"
  }, {
    path: "/it/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___it"
  }, {
    path: "/it/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___it"
  }, {
    path: "/it/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___it"
  }, {
    path: "/it/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___it"
  }, {
    path: "/it/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___it"
  }, {
    path: "/it/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___it"
  }, {
    path: "/it/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___it"
  }, {
    path: "/it/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___it"
  }, {
    path: "/it/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___it"
  }, {
    path: "/it/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___it"
  }, {
    path: "/it/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___it"
  }, {
    path: "/it/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___it"
  }, {
    path: "/it/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___it"
  }, {
    path: "/it/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___it"
  }, {
    path: "/it/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___it"
  }, {
    path: "/it/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___it"
  }, {
    path: "/it/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___it"
  }, {
    path: "/it/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___it"
  }, {
    path: "/it/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___it"
  }, {
    path: "/it/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___it"
  }, {
    path: "/it/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___it"
  }, {
    path: "/it/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___it"
  }, {
    path: "/it/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___it"
  }, {
    path: "/it/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___it"
  }, {
    path: "/it/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___it"
  }, {
    path: "/it/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___it"
  }, {
    path: "/it/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___it"
  }, {
    path: "/it/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___it"
  }, {
    path: "/it/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___it"
  }, {
    path: "/it/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___it"
  }, {
    path: "/it/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___it"
  }, {
    path: "/it/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___it"
  }, {
    path: "/it/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___it"
  }, {
    path: "/it/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___it"
  }, {
    path: "/it/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___it"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___ru"
  }, {
    path: "/ru/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___ru"
  }, {
    path: "/ru/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___ru"
  }, {
    path: "/ru/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___ru"
  }, {
    path: "/ru/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___ru"
  }, {
    path: "/ru/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___ru"
  }, {
    path: "/ru/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___ru"
  }, {
    path: "/ru/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___ru"
  }, {
    path: "/ru/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___ru"
  }, {
    path: "/ru/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___ru"
  }, {
    path: "/ru/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___ru"
  }, {
    path: "/ru/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___ru"
  }, {
    path: "/ru/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___ru"
  }, {
    path: "/ru/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___ru"
  }, {
    path: "/ru/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___ru"
  }, {
    path: "/ru/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___ru"
  }, {
    path: "/ru/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___ru"
  }, {
    path: "/ru/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___ru"
  }, {
    path: "/ru/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___ru"
  }, {
    path: "/ru/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___ru"
  }, {
    path: "/ru/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___ru"
  }, {
    path: "/ru/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___ru"
  }, {
    path: "/ru/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___ru"
  }, {
    path: "/ru/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___ru"
  }, {
    path: "/ru/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___ru"
  }, {
    path: "/ru/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___ru"
  }, {
    path: "/ru/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___ru"
  }, {
    path: "/ru/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___ru"
  }, {
    path: "/ru/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___ru"
  }, {
    path: "/ru/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___ru"
  }, {
    path: "/ru/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___ru"
  }, {
    path: "/ru/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___ru"
  }, {
    path: "/ru/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___ru"
  }, {
    path: "/ru/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___ru"
  }, {
    path: "/ru/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___ru"
  }, {
    path: "/ru/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___ru"
  }, {
    path: "/ru/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___ru"
  }, {
    path: "/ru/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___ru"
  }, {
    path: "/ru/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___ru"
  }, {
    path: "/ru/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___ru"
  }, {
    path: "/ru/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___ru"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/achaval-ferrer",
    component: _7cc308ff,
    name: "wine-guide-argentina-mendoza-achaval-ferrer___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/bodega-aleanna",
    component: _16c71e21,
    name: "wine-guide-argentina-mendoza-bodega-aleanna___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/bodega-alta-vista",
    component: _38ee3341,
    name: "wine-guide-argentina-mendoza-bodega-alta-vista___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/bodega-catena-zapata",
    component: _50c0e152,
    name: "wine-guide-argentina-mendoza-bodega-catena-zapata___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/bodega-luigi-bosca",
    component: _0110cbe0,
    name: "wine-guide-argentina-mendoza-bodega-luigi-bosca___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/familia-zuccardi",
    component: _e52f5846,
    name: "wine-guide-argentina-mendoza-familia-zuccardi___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/michel-rolland",
    component: _3befb8be,
    name: "wine-guide-argentina-mendoza-michel-rolland___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/rutini-wines",
    component: _0f91c988,
    name: "wine-guide-argentina-mendoza-rutini-wines___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/trapiche",
    component: _31c18090,
    name: "wine-guide-argentina-mendoza-trapiche___sv"
  }, {
    path: "/sv/wine-guide/argentina/mendoza/vina-cobos",
    component: _3902a8bf,
    name: "wine-guide-argentina-mendoza-vina-cobos___sv"
  }, {
    path: "/sv/wine-guide/argentina/patagonia/bodega-noemia-patagonia",
    component: _f350e956,
    name: "wine-guide-argentina-patagonia-bodega-noemia-patagonia___sv"
  }, {
    path: "/sv/wine-guide/argentina/patagonia/bodegas-chacra",
    component: _6f99eb4c,
    name: "wine-guide-argentina-patagonia-bodegas-chacra___sv"
  }, {
    path: "/sv/wine-guide/argentina/salta/bodega-el-esterco",
    component: _6d258f25,
    name: "wine-guide-argentina-salta-bodega-el-esterco___sv"
  }, {
    path: "/sv/wine-guide/argentina/salta/bodegas-etchart",
    component: _2542c174,
    name: "wine-guide-argentina-salta-bodegas-etchart___sv"
  }, {
    path: "/sv/wine-guide/argentina/salta/finca-quara-winery",
    component: _7b3b16c4,
    name: "wine-guide-argentina-salta-finca-quara-winery___sv"
  }, {
    path: "/sv/wine-guide/australia/barrossa-valley/penfolds",
    component: _9a24eb4e,
    name: "wine-guide-australia-barrossa-valley-penfolds___sv"
  }, {
    path: "/sv/wine-guide/australia/barrossa-valley/torbrek",
    component: _1002c0bd,
    name: "wine-guide-australia-barrossa-valley-torbrek___sv"
  }, {
    path: "/sv/wine-guide/australia/clare-valley/jim-barry-wines",
    component: _c1332654,
    name: "wine-guide-australia-clare-valley-jim-barry-wines___sv"
  }, {
    path: "/sv/wine-guide/australia/eden-valley/henschke",
    component: _c0e63c50,
    name: "wine-guide-australia-eden-valley-henschke___sv"
  }, {
    path: "/sv/wine-guide/australia/mclaren-valley/fox-creek",
    component: _4e1f0092,
    name: "wine-guide-australia-mclaren-valley-fox-creek___sv"
  }, {
    path: "/sv/wine-guide/australia/yarra-valley/mount-mary",
    component: _13a5fa62,
    name: "wine-guide-australia-yarra-valley-mount-mary___sv"
  }, {
    path: "/sv/wine-guide/chile/aconcagua/sena",
    component: _0b61a2a2,
    name: "wine-guide-chile-aconcagua-sena___sv"
  }, {
    path: "/sv/wine-guide/chile/aconcagua/vina-errazuriz",
    component: _61995da2,
    name: "wine-guide-chile-aconcagua-vina-errazuriz___sv"
  }, {
    path: "/sv/wine-guide/chile/aconcagua/vina-von-sibenthal",
    component: _5095085f,
    name: "wine-guide-chile-aconcagua-vina-von-sibenthal___sv"
  }, {
    path: "/sv/wine-guide/chile/colchagua/montes-folly",
    component: _643dd6c0,
    name: "wine-guide-chile-colchagua-montes-folly___sv"
  }, {
    path: "/sv/wine-guide/chile/maipo/almaviva-wines",
    component: _7622e2b8,
    name: "wine-guide-chile-maipo-almaviva-wines___sv"
  }, {
    path: "/sv/wine-guide/chile/maipo/concha-y-toto",
    component: _16f92903,
    name: "wine-guide-chile-maipo-concha-y-toto___sv"
  }, {
    path: "/sv/wine-guide/chile/maipo/don-melchor",
    component: _693b4010,
    name: "wine-guide-chile-maipo-don-melchor___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/alter-ego-de-palmer",
    component: _5d2388ea,
    name: "wine-guide-france-bordeaux-alter-ego-de-palmer___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/carillon-dangelus",
    component: _ca2cd6ac,
    name: "wine-guide-france-bordeaux-carillon-dangelus___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/carruades-de-lafite",
    component: _fa2adb74,
    name: "wine-guide-france-bordeaux-carruades-de-lafite___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-angelus",
    component: _393889ee,
    name: "wine-guide-france-bordeaux-chateau-angelus___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-ausone",
    component: _517dbe8d,
    name: "wine-guide-france-bordeaux-chateau-ausone___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-belgrave",
    component: _cffb373c,
    name: "wine-guide-france-bordeaux-chateau-belgrave___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-calon-segur",
    component: _b75a3f88,
    name: "wine-guide-france-bordeaux-chateau-calon-segur___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-cantemerle",
    component: _25c14df8,
    name: "wine-guide-france-bordeaux-chateau-cantemerle___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-cantenac-brown",
    component: _0cf2e9de,
    name: "wine-guide-france-bordeaux-chateau-cantenac-brown___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-climens",
    component: _0deef827,
    name: "wine-guide-france-bordeaux-chateau-climens___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-clinet",
    component: _9d22fe7a,
    name: "wine-guide-france-bordeaux-chateau-clinet___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-cos-destournel",
    component: _788baf92,
    name: "wine-guide-france-bordeaux-chateau-cos-destournel___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-cos-labory",
    component: _087834d6,
    name: "wine-guide-france-bordeaux-chateau-cos-labory___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-coutet",
    component: _eba16880,
    name: "wine-guide-france-bordeaux-chateau-coutet___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-de-camensac",
    component: _465572da,
    name: "wine-guide-france-bordeaux-chateau-de-camensac___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-de-ferrand",
    component: _54e58ab2,
    name: "wine-guide-france-bordeaux-chateau-de-ferrand___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-de-fieuzal",
    component: _2a1a8fe8,
    name: "wine-guide-france-bordeaux-chateau-de-fieuzal___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-ducru-beaucaillou",
    component: _4fab60ac,
    name: "wine-guide-france-bordeaux-chateau-ducru-beaucaillou___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-dyquem-bordeaux",
    component: _4542dd94,
    name: "wine-guide-france-bordeaux-chateau-dyquem-bordeaux___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-figeac",
    component: _1de02609,
    name: "wine-guide-france-bordeaux-chateau-figeac___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-fleur-cardinale",
    component: _5c5fc01e,
    name: "wine-guide-france-bordeaux-chateau-fleur-cardinale___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-fombrauge",
    component: _102602d0,
    name: "wine-guide-france-bordeaux-chateau-fombrauge___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-giscours",
    component: _5348c71f,
    name: "wine-guide-france-bordeaux-chateau-giscours___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-grand-puy-lacoste",
    component: _63e0ce13,
    name: "wine-guide-france-bordeaux-chateau-grand-puy-lacoste___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-gruaud-larose",
    component: _2fff9f95,
    name: "wine-guide-france-bordeaux-chateau-gruaud-larose___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-haut-bages-liberal",
    component: _33b4b317,
    name: "wine-guide-france-bordeaux-chateau-haut-bages-liberal___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-haut-batailley",
    component: _47f6317c,
    name: "wine-guide-france-bordeaux-chateau-haut-batailley___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-haut-brion",
    component: _65e4cf49,
    name: "wine-guide-france-bordeaux-chateau-haut-brion___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-haut-marbuzet",
    component: _6cbdb3d7,
    name: "wine-guide-france-bordeaux-chateau-haut-marbuzet___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-kirwan",
    component: _12c77656,
    name: "wine-guide-france-bordeaux-chateau-kirwan___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-la-fleur-petrus",
    component: _78341744,
    name: "wine-guide-france-bordeaux-chateau-la-fleur-petrus___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-la-gurgue",
    component: _c45cf216,
    name: "wine-guide-france-bordeaux-chateau-la-gurgue___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-la-mission-haut-brion",
    component: _4a45e5e6,
    name: "wine-guide-france-bordeaux-chateau-la-mission-haut-brion___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-la-tour-carnet",
    component: _7a6f4068,
    name: "wine-guide-france-bordeaux-chateau-la-tour-carnet___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-lafite-rothschild",
    component: _5bfb76c8,
    name: "wine-guide-france-bordeaux-chateau-lafite-rothschild___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-lagrange",
    component: _49f4169e,
    name: "wine-guide-france-bordeaux-chateau-lagrange___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-lanessan",
    component: _4e24793f,
    name: "wine-guide-france-bordeaux-chateau-lanessan___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-langoa-barton",
    component: _67f922b7,
    name: "wine-guide-france-bordeaux-chateau-langoa-barton___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-larrivet-haut-brion",
    component: _444ad12e,
    name: "wine-guide-france-bordeaux-chateau-larrivet-haut-brion___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-latour",
    component: _a0518cda,
    name: "wine-guide-france-bordeaux-chateau-latour___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-latour-martillac",
    component: _7c0256bb,
    name: "wine-guide-france-bordeaux-chateau-latour-martillac___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-leoville-barton",
    component: _aa99f40a,
    name: "wine-guide-france-bordeaux-chateau-leoville-barton___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-leoville-las-cases",
    component: _f00585ee,
    name: "wine-guide-france-bordeaux-chateau-leoville-las-cases___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-leoville-poyferre",
    component: _244d79c7,
    name: "wine-guide-france-bordeaux-chateau-leoville-poyferre___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-les-grands-chenes",
    component: _3d4f4459,
    name: "wine-guide-france-bordeaux-chateau-les-grands-chenes___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-levangile",
    component: _4dfb510f,
    name: "wine-guide-france-bordeaux-chateau-levangile___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-lynch-bages",
    component: _69db1a79,
    name: "wine-guide-france-bordeaux-chateau-lynch-bages___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-malescasse",
    component: _624cb7e7,
    name: "wine-guide-france-bordeaux-chateau-malescasse___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-margaux",
    component: _5f766605,
    name: "wine-guide-france-bordeaux-chateau-margaux___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-marquis-dalesme",
    component: _402e76de,
    name: "wine-guide-france-bordeaux-chateau-marquis-dalesme___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-maucaillou",
    component: _6ebba614,
    name: "wine-guide-france-bordeaux-chateau-maucaillou___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-monbousquet",
    component: _28f19598,
    name: "wine-guide-france-bordeaux-chateau-monbousquet___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-montrose",
    component: _0d18b3fd,
    name: "wine-guide-france-bordeaux-chateau-montrose___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-mouton-rothschild",
    component: _2503e41e,
    name: "wine-guide-france-bordeaux-chateau-mouton-rothschild___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-palmer",
    component: _864cd2b6,
    name: "wine-guide-france-bordeaux-chateau-palmer___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pape-clement",
    component: _74b4a019,
    name: "wine-guide-france-bordeaux-chateau-pape-clement___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pavie",
    component: _c6f096aa,
    name: "wine-guide-france-bordeaux-chateau-pavie___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pedesclaux",
    component: _475736a4,
    name: "wine-guide-france-bordeaux-chateau-pedesclaux___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-petrus",
    component: _70cf8357,
    name: "wine-guide-france-bordeaux-chateau-petrus___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-phelan-segur",
    component: _36214d12,
    name: "wine-guide-france-bordeaux-chateau-phelan-segur___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pichon-lalande",
    component: _528d967b,
    name: "wine-guide-france-bordeaux-chateau-pichon-lalande___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pichon-longueville-baron",
    component: _ccdcb83e,
    name: "wine-guide-france-bordeaux-chateau-pichon-longueville-baron___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pontet-canet",
    component: _63761ba8,
    name: "wine-guide-france-bordeaux-chateau-pontet-canet___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-pouget",
    component: _42390806,
    name: "wine-guide-france-bordeaux-chateau-pouget___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-poujeaux",
    component: _3e1b5e4e,
    name: "wine-guide-france-bordeaux-chateau-poujeaux___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-rauzan-segla",
    component: _4bcef544,
    name: "wine-guide-france-bordeaux-chateau-rauzan-segla___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-rieussec",
    component: _5143752a,
    name: "wine-guide-france-bordeaux-chateau-rieussec___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-sigalas-rabaud",
    component: _14a98942,
    name: "wine-guide-france-bordeaux-chateau-sigalas-rabaud___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-taillefer",
    component: _1ec1d858,
    name: "wine-guide-france-bordeaux-chateau-taillefer___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/chateau-talbot",
    component: _5947748e,
    name: "wine-guide-france-bordeaux-chateau-talbot___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/cheval-blanc",
    component: _f6b9ac0c,
    name: "wine-guide-france-bordeaux-cheval-blanc___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/echo-lynch-bages",
    component: _6dec3cbe,
    name: "wine-guide-france-bordeaux-echo-lynch-bages___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/le-petit-mouton",
    component: _29a0514d,
    name: "wine-guide-france-bordeaux-le-petit-mouton___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/marquis-de-calon",
    component: _39584edc,
    name: "wine-guide-france-bordeaux-marquis-de-calon___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/pagodes-de-cos",
    component: _21e0c6a9,
    name: "wine-guide-france-bordeaux-pagodes-de-cos___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/petit-haut-lafitte",
    component: _4faa4cd5,
    name: "wine-guide-france-bordeaux-petit-haut-lafitte___sv"
  }, {
    path: "/sv/wine-guide/france/bordeaux/vieux-chateau-certan",
    component: _03abc4af,
    name: "wine-guide-france-bordeaux-vieux-chateau-certan___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/chateau-batard",
    component: _7a1017a8,
    name: "wine-guide-france-burgundy-chateau-batard___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-alain-hudelot-noellat",
    component: _4c49e75d,
    name: "wine-guide-france-burgundy-domaine-alain-hudelot-noellat___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-armand-rousseau",
    component: _75201796,
    name: "wine-guide-france-burgundy-domaine-armand-rousseau___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-bruno-colinv",
    component: _7548c4e6,
    name: "wine-guide-france-burgundy-domaine-bruno-colinv___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-claude-dugat",
    component: _cc7e43b0,
    name: "wine-guide-france-burgundy-domaine-claude-dugat___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-coche-dury",
    component: _5372da7f,
    name: "wine-guide-france-burgundy-domaine-coche-dury___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-compte-georges-vogue",
    component: _7c6dbb66,
    name: "wine-guide-france-burgundy-domaine-compte-georges-vogue___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-comtes-lafon",
    component: _3ac4065c,
    name: "wine-guide-france-burgundy-domaine-comtes-lafon___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-confuron-cotetidot",
    component: _1097e9b8,
    name: "wine-guide-france-burgundy-domaine-confuron-cotetidot___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-dugat-py",
    component: _694670ca,
    name: "wine-guide-france-burgundy-domaine-dugat-py___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-dujac",
    component: _6c5e6edd,
    name: "wine-guide-france-burgundy-domaine-dujac___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-emmanuel-rouget",
    component: _5a2c137a,
    name: "wine-guide-france-burgundy-domaine-emmanuel-rouget___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-etienne-sauzet",
    component: _308cc17a,
    name: "wine-guide-france-burgundy-domaine-etienne-sauzet___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-faiveley",
    component: _26c3bdb1,
    name: "wine-guide-france-burgundy-domaine-faiveley___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-georges-noellat",
    component: _0ad999c0,
    name: "wine-guide-france-burgundy-domaine-georges-noellat___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-georges-roumier",
    component: _62529cca,
    name: "wine-guide-france-burgundy-domaine-georges-roumier___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-harmand-geoffroy",
    component: _ff1ea2fa,
    name: "wine-guide-france-burgundy-domaine-harmand-geoffroy___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-henri-boillot",
    component: _da968844,
    name: "wine-guide-france-burgundy-domaine-henri-boillot___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-hubert-lignier",
    component: _033f0725,
    name: "wine-guide-france-burgundy-domaine-hubert-lignier___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-jean-tardy",
    component: _5e0dfc03,
    name: "wine-guide-france-burgundy-domaine-jean-tardy___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-joseph-drouhin",
    component: _28b15d6e,
    name: "wine-guide-france-burgundy-domaine-joseph-drouhin___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-lignier-michelot",
    component: _39f0abf6,
    name: "wine-guide-france-burgundy-domaine-lignier-michelot___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-louis-jadot",
    component: _c1c39fba,
    name: "wine-guide-france-burgundy-domaine-louis-jadot___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-mark-haisma",
    component: _d27ef58e,
    name: "wine-guide-france-burgundy-domaine-mark-haisma___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-meo-camuzet",
    component: _67580eef,
    name: "wine-guide-france-burgundy-domaine-meo-camuzet___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-paul-pernot",
    component: _0dcb9d3d,
    name: "wine-guide-france-burgundy-domaine-paul-pernot___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-ramonet",
    component: _28386824,
    name: "wine-guide-france-burgundy-domaine-ramonet___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-raveneau",
    component: _3fda1237,
    name: "wine-guide-france-burgundy-domaine-raveneau___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-rene-lequin-colin",
    component: _07b3c91f,
    name: "wine-guide-france-burgundy-domaine-rene-lequin-colin___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-robert-groffier",
    component: _70fc711f,
    name: "wine-guide-france-burgundy-domaine-robert-groffier___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-stephane-magnien",
    component: _89911958,
    name: "wine-guide-france-burgundy-domaine-stephane-magnien___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-terres-dorees",
    component: _3596875c,
    name: "wine-guide-france-burgundy-domaine-terres-dorees___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-vincent-bouzereau",
    component: _37c3c0dc,
    name: "wine-guide-france-burgundy-domaine-vincent-bouzereau___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-vincent-dauvissat",
    component: _21c36bd6,
    name: "wine-guide-france-burgundy-domaine-vincent-dauvissat___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/domaine-william-fevre",
    component: _5054b582,
    name: "wine-guide-france-burgundy-domaine-william-fevre___sv"
  }, {
    path: "/sv/wine-guide/france/burgundy/jean-marc-boillot",
    component: _daffff68,
    name: "wine-guide-france-burgundy-jean-marc-boillot___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/bollinger",
    component: _331fcd38,
    name: "wine-guide-france-champagne-bollinger___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/dom-perignon",
    component: _55e33912,
    name: "wine-guide-france-champagne-dom-perignon___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/krug",
    component: _370b5393,
    name: "wine-guide-france-champagne-krug___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/laurent-perrier",
    component: _6493ab3a,
    name: "wine-guide-france-champagne-laurent-perrier___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/louis-roederer",
    component: _70ea9241,
    name: "wine-guide-france-champagne-louis-roederer___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/philipponnat",
    component: _c375b764,
    name: "wine-guide-france-champagne-philipponnat___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/pol-roger",
    component: _82ff34e6,
    name: "wine-guide-france-champagne-pol-roger___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/ruinart",
    component: _35f00d5e,
    name: "wine-guide-france-champagne-ruinart___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/salon",
    component: _c8fb115a,
    name: "wine-guide-france-champagne-salon___sv"
  }, {
    path: "/sv/wine-guide/france/champagne/taittinger",
    component: _5f8d3d4d,
    name: "wine-guide-france-champagne-taittinger___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/chateau-beaucastel",
    component: _05674d6f,
    name: "wine-guide-france-rhone-chateau-beaucastel___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/chateau-rayas",
    component: _14aaa90c,
    name: "wine-guide-france-rhone-chateau-rayas___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/clos-des-papes",
    component: _8576f150,
    name: "wine-guide-france-rhone-clos-des-papes___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/delas-freres",
    component: _0571f586,
    name: "wine-guide-france-rhone-delas-freres___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/domaine-de-la-janasse",
    component: _7311a64d,
    name: "wine-guide-france-rhone-domaine-de-la-janasse___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/etienne-guigal",
    component: _25e6e130,
    name: "wine-guide-france-rhone-etienne-guigal___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/henri-bonneau",
    component: _c7ce88de,
    name: "wine-guide-france-rhone-henri-bonneau___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/jean-louis-chave",
    component: _831e68ca,
    name: "wine-guide-france-rhone-jean-louis-chave___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/m-chapoutier",
    component: _5a06e3fc,
    name: "wine-guide-france-rhone-m-chapoutier___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/paul-jaboulet-aine",
    component: _0920d77a,
    name: "wine-guide-france-rhone-paul-jaboulet-aine___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/pierre-usseglio",
    component: _b401844e,
    name: "wine-guide-france-rhone-pierre-usseglio___sv"
  }, {
    path: "/sv/wine-guide/france/rhone/rene-rostaing",
    component: _18d5f428,
    name: "wine-guide-france-rhone-rene-rostaing___sv"
  }, {
    path: "/sv/wine-guide/germany/ahr/jean-stodden",
    component: _63930222,
    name: "wine-guide-germany-ahr-jean-stodden___sv"
  }, {
    path: "/sv/wine-guide/germany/baden/bernhard-huber",
    component: _a9980cce,
    name: "wine-guide-germany-baden-bernhard-huber___sv"
  }, {
    path: "/sv/wine-guide/germany/franken/rudolf-furst",
    component: _768f344c,
    name: "wine-guide-germany-franken-rudolf-furst___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/carl-von-schubert",
    component: _3fad7d3e,
    name: "wine-guide-germany-mosel-saar-ruwer-carl-von-schubert___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/egon-muller",
    component: _6365b3d3,
    name: "wine-guide-germany-mosel-saar-ruwer-egon-muller___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/jj-prum",
    component: _ca0f458e,
    name: "wine-guide-germany-mosel-saar-ruwer-jj-prum___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/peter-lauer",
    component: _1db3287e,
    name: "wine-guide-germany-mosel-saar-ruwer-peter-lauer___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/van-volxem",
    component: _591fa295,
    name: "wine-guide-germany-mosel-saar-ruwer-van-volxem___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/weingut-forstmeister-geltz-zilliken",
    component: _d666267a,
    name: "wine-guide-germany-mosel-saar-ruwer-weingut-forstmeister-geltz-zilliken___sv"
  }, {
    path: "/sv/wine-guide/germany/mosel-saar-ruwer/wwe-thanisch",
    component: _045f88b6,
    name: "wine-guide-germany-mosel-saar-ruwer-wwe-thanisch___sv"
  }, {
    path: "/sv/wine-guide/germany/rheinhessen/klaus-peter-keller",
    component: _5e91f3f6,
    name: "wine-guide-germany-rheinhessen-klaus-peter-keller___sv"
  }, {
    path: "/sv/wine-guide/germany/rheinhessen/von-buhl",
    component: _90c77a06,
    name: "wine-guide-germany-rheinhessen-von-buhl___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/bruno-giacosa",
    component: _c44b16a8,
    name: "wine-guide-italy-piedmont-bruno-giacosa___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/cantina-roberto-voerzio",
    component: _747ffd5f,
    name: "wine-guide-italy-piedmont-cantina-roberto-voerzio___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/conterno-fantino",
    component: _f3d5a19c,
    name: "wine-guide-italy-piedmont-conterno-fantino___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/g-d-vajra",
    component: _77ea5ad9,
    name: "wine-guide-italy-piedmont-g-d-vajra___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/gaja",
    component: _43a1fc27,
    name: "wine-guide-italy-piedmont-gaja___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/giacomo-conterno",
    component: _1da6a014,
    name: "wine-guide-italy-piedmont-giacomo-conterno___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/giuseppe-mascarello-e-figlio",
    component: _5a6ec4a6,
    name: "wine-guide-italy-piedmont-giuseppe-mascarello-e-figlio___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/massolino",
    component: _526710d3,
    name: "wine-guide-italy-piedmont-massolino___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/poderi-aldo-conterno",
    component: _0648a4d2,
    name: "wine-guide-italy-piedmont-poderi-aldo-conterno___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/roagna",
    component: _ff3ae2a8,
    name: "wine-guide-italy-piedmont-roagna___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/tenuta-la-giustiniana",
    component: _6a72c5ce,
    name: "wine-guide-italy-piedmont-tenuta-la-giustiniana___sv"
  }, {
    path: "/sv/wine-guide/italy/piedmont/vigne-marina-coppi",
    component: _af3117f4,
    name: "wine-guide-italy-piedmont-vigne-marina-coppi___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/avignonesi",
    component: _57aee5c4,
    name: "wine-guide-italy-tuscany-avignonesi___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/azienda-agricola-casanova-di-neri",
    component: _4ec214a8,
    name: "wine-guide-italy-tuscany-azienda-agricola-casanova-di-neri___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/azienda-salvioni",
    component: _bb2e8026,
    name: "wine-guide-italy-tuscany-azienda-salvioni___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/castiglione-del-bosco",
    component: _ee66c4fc,
    name: "wine-guide-italy-tuscany-castiglione-del-bosco___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/conti-constani",
    component: _30b72b6c,
    name: "wine-guide-italy-tuscany-conti-constani___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/fattoria-le-pupille",
    component: _2ae9219d,
    name: "wine-guide-italy-tuscany-fattoria-le-pupille___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/fattoria-poggio-di-sotto",
    component: _764af1f9,
    name: "wine-guide-italy-tuscany-fattoria-poggio-di-sotto___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/fontodi",
    component: _627dff18,
    name: "wine-guide-italy-tuscany-fontodi___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/frescobaldi",
    component: _1cbff03f,
    name: "wine-guide-italy-tuscany-frescobaldi___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/gianfranco-soldera",
    component: _327ac738,
    name: "wine-guide-italy-tuscany-gianfranco-soldera___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/isole-e-olena",
    component: _aa82ed92,
    name: "wine-guide-italy-tuscany-isole-e-olena___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/marchesi-antinori",
    component: _7cc7fdc2,
    name: "wine-guide-italy-tuscany-marchesi-antinori___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/podere-poggio-scalette",
    component: _0bffdff4,
    name: "wine-guide-italy-tuscany-podere-poggio-scalette___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/poderi-boscarelli",
    component: _26b544ca,
    name: "wine-guide-italy-tuscany-poderi-boscarelli___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/rocca-di-frassinello",
    component: _0d428c02,
    name: "wine-guide-italy-tuscany-rocca-di-frassinello___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/tenuta-dell-ornellaia",
    component: _acbefe64,
    name: "wine-guide-italy-tuscany-tenuta-dell-ornellaia___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/tenuta-di-trinoro",
    component: _412f29dc,
    name: "wine-guide-italy-tuscany-tenuta-di-trinoro___sv"
  }, {
    path: "/sv/wine-guide/italy/tuscany/tenuta-san-guido",
    component: _2fff9f6e,
    name: "wine-guide-italy-tuscany-tenuta-san-guido___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/casa-ferreirinha",
    component: _73c94402,
    name: "wine-guide-portugal-douro-valley-casa-ferreirinha___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/howards-folly",
    component: _657099ad,
    name: "wine-guide-portugal-douro-valley-howards-folly___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/prats-and-symington",
    component: _1d4e2226,
    name: "wine-guide-portugal-douro-valley-prats-and-symington___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/quinta-do-crasto",
    component: _713d919d,
    name: "wine-guide-portugal-douro-valley-quinta-do-crasto___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/quinta-do-vale-meao",
    component: _71093b1e,
    name: "wine-guide-portugal-douro-valley-quinta-do-vale-meao___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/quinta-do-vallado",
    component: _15ed7cb0,
    name: "wine-guide-portugal-douro-valley-quinta-do-vallado___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/quinta-do-vesuvio",
    component: _0970150c,
    name: "wine-guide-portugal-douro-valley-quinta-do-vesuvio___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/roquette-cazes-quinta",
    component: _2661e6bf,
    name: "wine-guide-portugal-douro-valley-roquette-cazes-quinta___sv"
  }, {
    path: "/sv/wine-guide/portugal/douro-valley/symington",
    component: _84f78cdc,
    name: "wine-guide-portugal-douro-valley-symington___sv"
  }, {
    path: "/sv/wine-guide/south-africa/constantia/restless-river",
    component: _5ddf31c7,
    name: "wine-guide-south-africa-constantia-restless-river___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch/alto-wine-estate",
    component: _840db0fc,
    name: "wine-guide-south-africa-stellenbosch-alto-wine-estate___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch/kanonkop-paul-sauer",
    component: _80b8f8c4,
    name: "wine-guide-south-africa-stellenbosch-kanonkop-paul-sauer___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch/meerlust-estate",
    component: _8b721cbe,
    name: "wine-guide-south-africa-stellenbosch-meerlust-estate___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch/savanha-wines",
    component: _537ac37c,
    name: "wine-guide-south-africa-stellenbosch-savanha-wines___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch/the-sadie-family-wines",
    component: _715e7c51,
    name: "wine-guide-south-africa-stellenbosch-the-sadie-family-wines___sv"
  }, {
    path: "/sv/wine-guide/south-africa/stellenbosch/thelema-mountain-vineyards",
    component: _64646ed7,
    name: "wine-guide-south-africa-stellenbosch-thelema-mountain-vineyards___sv"
  }, {
    path: "/sv/wine-guide/spain/ribera-del-duero/pingus",
    component: _3d09a621,
    name: "wine-guide-spain-ribera-del-duero-pingus___sv"
  }, {
    path: "/sv/wine-guide/spain/ribera-del-duero/vega-sicilia",
    component: _3a77f395,
    name: "wine-guide-spain-ribera-del-duero-vega-sicilia___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/abreu-vineyards",
    component: _f48e68fe,
    name: "wine-guide-usa-napa-abreu-vineyards___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/caymus-vineyards",
    component: _2da3c4a4,
    name: "wine-guide-usa-napa-caymus-vineyards___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/christian-moueix-dominus",
    component: _79ed6ebf,
    name: "wine-guide-usa-napa-christian-moueix-dominus___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/colgin-cellars",
    component: _182dc051,
    name: "wine-guide-usa-napa-colgin-cellars___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/harlan-estates",
    component: _09ae85cc,
    name: "wine-guide-usa-napa-harlan-estates___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/kapcsandy-winery",
    component: _1f5a0243,
    name: "wine-guide-usa-napa-kapcsandy-winery___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/opus-one",
    component: _6b8e0fea,
    name: "wine-guide-usa-napa-opus-one___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/robert-mondovi-vineyards",
    component: _3e17ba93,
    name: "wine-guide-usa-napa-robert-mondovi-vineyards___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/scarecrow-wines",
    component: _652bbbb6,
    name: "wine-guide-usa-napa-scarecrow-wines___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/schrader-cellars",
    component: _37751622,
    name: "wine-guide-usa-napa-schrader-cellars___sv"
  }, {
    path: "/sv/wine-guide/usa/napa/screaming-eagle",
    component: _28919fb0,
    name: "wine-guide-usa-napa-screaming-eagle___sv"
  }, {
    path: "/sv/wine-guide/usa/sonoma/ridge-vineyards",
    component: _ab6040d0,
    name: "wine-guide-usa-sonoma-ridge-vineyards___sv"
  }, {
    path: "/sv/wine-guide/usa/sonoma/sine-qua-non",
    component: _c5845f3c,
    name: "wine-guide-usa-sonoma-sine-qua-non___sv"
  }, {
    path: "/sv/wine-guide/usa/sonoma/verite-vigneron",
    component: _5b55c9c7,
    name: "wine-guide-usa-sonoma-verite-vigneron___sv"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/de/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___de"
  }, {
    path: "/de/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___de"
  }, {
    path: "/de/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___de"
  }, {
    path: "/de/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___de"
  }, {
    path: "/de/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___de"
  }, {
    path: "/de/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___de"
  }, {
    path: "/es/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___es"
  }, {
    path: "/es/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___es"
  }, {
    path: "/es/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___es"
  }, {
    path: "/es/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___es"
  }, {
    path: "/es/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___es"
  }, {
    path: "/es/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___es"
  }, {
    path: "/fr/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___fr"
  }, {
    path: "/fr/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___fr"
  }, {
    path: "/fr/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___fr"
  }, {
    path: "/fr/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___fr"
  }, {
    path: "/fr/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___fr"
  }, {
    path: "/fr/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___fr"
  }, {
    path: "/it/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___it"
  }, {
    path: "/it/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___it"
  }, {
    path: "/it/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___it"
  }, {
    path: "/it/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___it"
  }, {
    path: "/it/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___it"
  }, {
    path: "/it/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___it"
  }, {
    path: "/ru/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___ru"
  }, {
    path: "/ru/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___ru"
  }, {
    path: "/ru/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___ru"
  }, {
    path: "/ru/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___ru"
  }, {
    path: "/ru/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___ru"
  }, {
    path: "/ru/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___ru"
  }, {
    path: "/sv/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___sv"
  }, {
    path: "/sv/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___sv"
  }, {
    path: "/sv/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___sv"
  }, {
    path: "/sv/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___sv"
  }, {
    path: "/sv/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___sv"
  }, {
    path: "/sv/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___sv"
  }, {
    path: "/de/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___de"
  }, {
    path: "/de/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___de"
  }, {
    path: "/es/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___es"
  }, {
    path: "/es/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___es"
  }, {
    path: "/fr/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___fr"
  }, {
    path: "/fr/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___fr"
  }, {
    path: "/it/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___it"
  }, {
    path: "/it/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___it"
  }, {
    path: "/ru/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___ru"
  }, {
    path: "/ru/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___ru"
  }, {
    path: "/sv/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___sv"
  }, {
    path: "/sv/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___sv"
  }, {
    path: "/de/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___de"
  }, {
    path: "/de/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___de"
  }, {
    path: "/es/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___es"
  }, {
    path: "/es/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___es"
  }, {
    path: "/fr/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___fr"
  }, {
    path: "/fr/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___fr"
  }, {
    path: "/it/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___it"
  }, {
    path: "/it/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___it"
  }, {
    path: "/ru/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___ru"
  }, {
    path: "/ru/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___ru"
  }, {
    path: "/sv/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___sv"
  }, {
    path: "/sv/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___sv"
  }, {
    path: "/collection-details/:collectionId?",
    component: _6acfc9b4,
    name: "collection-details-collectionId___en"
  }, {
    path: "/gettheapp/:uk",
    component: _6d2421dc,
    name: "gettheapp-uk___en"
  }, {
    path: "/my-account/:preselectedTab?",
    component: _50c4b906,
    name: "my-account-preselectedTab___en"
  }, {
    path: "/trading-desk/:EnPrimeur?",
    component: _a828d138,
    name: "trading-desk-EnPrimeur___en"
  }, {
    path: "/wine-trading-buy/:wineId?",
    component: _5640e214,
    name: "wine-trading-buy-wineId___en"
  }, {
    path: "/wine-trading-sell/:wineId?",
    component: _fed30148,
    name: "wine-trading-sell-wineId___en"
  }, {
    path: "/wine-details/:wineId?/:caseSizeId",
    component: _01cb925b,
    name: "wine-details-wineId-caseSizeId___en"
  }, {
    path: "/wine-performance/:wineId?/:caseSizeId",
    component: _6472366e,
    name: "wine-performance-wineId-caseSizeId___en"
  }, {
    path: "/wine-details/:wineId?/:caseSizeId?/:wineName?",
    component: _3c80641c,
    name: "wine-details-wineId-caseSizeId-wineName___en"
  }, {
    path: "/wine-performance/:wineId?/:caseSizeId?/:wineName?",
    component: _1c90cf8a,
    name: "wine-performance-wineId-caseSizeId-wineName___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
